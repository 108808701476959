/* 
------------------------------------------------------------------------------------
  (1) '/register' URL'indeki 'Öğrenci / Mezun Kaydı' formunun tipinin düzeltilmesi 
------------------------------------------------------------------------------------
*/

form#studentRegistrationForm {
    max-width: 350px !important;
}

form#studentRegistrationForm div.form__form-group-field {
    padding-left: 0px !important;
    margin-left: 0px !important;
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;
}

form#studentRegistrationForm div.form__form-group-field:has(h5) { 
    max-width: 100% !important;
    width: 100% !important;
}

form#studentRegistrationForm div.student_register_form_group {
    flex-direction: row !important;
}

form#studentRegistrationForm label.checkbox-btn {
    display: inline-flex !important;
}

form#studentRegistrationForm button.form__form-group-label {
    line-height: 18px !important;
    font-size: 13px !important;
}

form#studentRegistrationForm span.form__form-group-label {
    width: 100% !important;
}

form#studentRegistrationForm span.radio-btn__label {
    white-space: normal !important;
    word-wrap: break-word !important;
    text-align: left !important;
}

div.terms-field-container > div {
    max-width: 350px !important;
    white-space: normal !important;
    word-wrap: break-word !important;
}

/* Aşağıdaki %70 ve %30'lar, sayfadaki mobil uyumluluğu ve tutarlı görünümü sağlamak amacıyla son çare olarak eklenmiştir. */
form#studentRegistrationForm div.terms-field-container span.radio-btn__label {
    width: 70% !important;
    margin-right: 30% !important;
}

/* 
------------------------------------------------------------------------------------
  (2) Küçük ekranlarda mobil uyumluluğun sağlanması
------------------------------------------------------------------------------------
*/
@media screen and (max-width: 450px) {

    form#studentRegistrationForm {
        width: calc(100vw - 80px) !important;
    }

    form#studentRegistrationForm div.terms-field-container span.radio-btn__label {
        width: calc(100vw - 100px) !important;
        margin-right: 0% !important;
    }
}

/* 
------------------------------------------------------------------------------------
  (3) ... 
------------------------------------------------------------------------------------
*/
