.page-title-filter-wrapper {
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;

  .go-back-button-toolbar {
    margin-bottom: 30px;
  }

  .page-title-filter-wrapper-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    flex-direction: row;
  }
  
  .page-title-filter__description {
    font-size: 15px;
  }

  .page-title {
    font-weight: 700;
    font-size: 20px;
    color: $color-content;
  }

  .form__button-toolbar.filter-toolbar{
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0;
  }

  .topbar__btn_filter{
    svg {
      height: 20px;
      width: 20px;
      fill: #000000;
    }
    .filter_btn_text{
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      margin-right: 3px;
    }
    &:before {
      display: none;
    }
  }
}
.margin_top_right{
  @media screen and (min-width: 768px) {
    margin-top: 10px!important;
  }
}