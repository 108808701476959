@import '~react-big-calendar/lib/css/react-big-calendar.css';

.calendar {
  height: 600px;
  font-family: 'Source Sans Pro', sans-serif;

  .rbc-date-cell {
    text-align: left;
    padding-right: 0;

    a {
      display: block;
      width: 100%;
      padding-left: 10px;
      padding-top: 5px;
      font-weight: 500;
      font-size: 13px;

      @include themify($themes) {
        color: themed('colorText');
      }
    }
  }

  .rbc-header {
    height: 40px;
    display: flex;
    color: $color-additional;

    @include themify($themes) {
      background-color: themed('colorBackgroundBody');
    }

    span {
      margin: auto;
      text-transform: uppercase;
      font-size: 12px;
    }

    a {
      display: block;
      margin: auto;
    }
  }

  .rbc-time-slot {
    display: flex;

    span {
      margin: auto 0;
    }
  }

  .rbc-off-range-bg {
    background-color: transparent;
  }

  .rbc-event {
    width: 100% !important;
    left: 0 !important;
    height: 20px;
    font-size: 10px;
    border-radius: 2px;
    padding: 2px;
    background-clip: content-box, padding-box;
  }
  .rbc-event-label {
    margin: 3px;
  }

  .rbc-event-content{
    margin: 0px 2px;
    font-size: 90%;
  }

  .rbc-toolbar-label {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    @include themify($themes) {
      color: themed('colorText2');
    }
    @media screen and (max-width: 1500px) {
      font-size: 13px;
    }
  }
  .rbc-agenda-table{
    .rbc-header {
      display: table-cell;
    }
  }
  .rbc-label {

    @include themify($themes) {
      color: themed('colorText');
    }
  }

  .rbc-show-more {
    background-color: transparent;
  }
  .rbc-toolbar{
    @media screen and (max-width:330px) {
      flex-flow: column;
    }
    @media screen and (min-width:330px) and (max-width:767px) {
      flex-flow: row;
    }
  }

  .rbc-toolbar button {
    height: 30px;
    border-radius: 3px;
    border: none;
    font-size: 11px;
    color: $color-additional;
    transition: all 0.3s;
    padding: 8px 14px;
    cursor: pointer;

    @include themify($themes) {
      background-color: themed('colorHover');
    }

    &.rbc-active {
      box-shadow: none;
      background-color: #ededed;
      pointer-events: none;

      @include themify($themes) {
        background-color: themed('colorFieldsBorder');
      }
    }

    &:hover {

      @include themify($themes) {
        color: themed('colorText');
        background-color: darken(themed('colorHover'), 2%);
      }
    }

    &:focus, &:active {
      outline: none;
      box-shadow: none;

      @include themify($themes) {
        color: themed('colorText');
      }
    }

    span {
      font-size: 10px;
      font-weight: 900;
    }
  }

  .rbc-btn-group {
    display: inline;
    min-width: auto;
    &:first-child {
      button {
        width: 25px;
        height: 25px;
        border: 1px solid #EFEFF6;
        background-color: #fff;
        margin-right: 4px;
        padding: 0;
        @include themify($themes) {
          color: themed('colorText2');
        }
      }
    }

    button {
      background-color: #F0F0F7;
      border: 1px solid #E8E9EC;
      margin-right: 4px;
      &:last-child:not(:first-child), &:not(:first-child):not(:last-child), &:first-child:not(:last-child) {
        border-radius: 3px;
      }
      @include themify($themes) {
        color: themed('colorText2');
      }
      display: table-cell;
      width: 50%;
    }
    .rbc-active {
      background-color: #F8F8FB;
      border: 1px solid #F5F6F7;
    }

  }

  .rbc-today {
    background-color: transparent;
    position: relative;

    &:before {
      content: "";
      height: 0px;
      width: 100%;
      background-color: $color-accent;
      top: 0;
      position: absolute;
    }
  }

  .rbc-current-time-indicator {
    display: none;
    background-color: $color-accent;

    &:before {
      background-color: $color-accent;
    }
  }

  &.calendar--small {
    height: 368px;

    .rbc-btn-group {
      margin-right: 8px;

      button {
        padding: 2px 6px;
      }
    }

    .rbc-toolbar-label {
      font-size: 13px;

      @media screen and (max-width: 1500px) {
        font-size: 12px;
      }
    }

    .rbc-time-view {
      .rbc-header {
        span {
          font-size: 13px;    

          @media screen and (max-width: 1500px) {
            font-size: 12px;
          }
        }
      }
    }
    
    .rbc-timeslot-group {
      .rbc-time-slot {
        .rbc-label{
          font-size: 13px;
    
          @media screen and (max-width: 1500px) {
            font-size: 12px;
          }
        }
      }
    }
  }

  .rbc-overlay {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);

    @include themify($themes) {
      color: themed('colorText');
      background-color: themed('colorBackground');
    }
  }
}

.calendar-label {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;

  &.calendar-label--red {
    background-color: $color-red;
  }

  &.calendar-label--green {
    background-color: $color-accent;
  }

  &.calendar-label--blue {
    background-color: $color-blue;
  }

  &.calendar-label--empty {
    background-color: #b3b3b3;
  }
  &.calendar-label--pending {
    background-color: #ffc107;
  }
  &.calendar-label--confirmed {
    background-color: #30d656;
  }
  &.calendar-label--rejected {
    background-color: #ff4861;
  }
  &.calendar-label--full_room {
    background-color: #46524d;
  }
  &.calendar-label--canceled {
    background-color: #9e4f00;
  }
  &.calendar-label--completed {
    background-color: #70bbfd;
  }
}

.rbc-allday-cell {
  display: none;
}

.rbc-time-view {
  border: none;
  .rbc-header {
    background-color: #fff !important;
    font-weight: 700;
    color: #43425D;

    span {
      font-size: 14px;
      text-transform: initial;
      
      @media screen and (max-width: 1500px) {
        font-size: 13px;
      }
    }
  }
}

.rbc-timeslot-group {
  min-height: 36px;
  .rbc-time-slot {

    .rbc-label{
      padding: 0 4px;
      font-weight: 700;
      color: #43425D;
      font-size: 14px;

      @media screen and (max-width: 1500px) {
        font-size: 13px;
      }
    }
  }
}

.rbc-time-content {
  border-top: none;
  padding-top: 0px;
}
.rbc-time-header.rbc-header-overflowing > .rbc-row:nth-of-type(2) {
  display: none;
}

.rbc-day-slot .rbc-events-container{
  margin-right: 0px;
}