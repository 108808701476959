.homepage {
  --mouse-icon-height: 70px;
  font-family: 'Montserrat', sans-serif;
  div{
    &:focus {
      outline: none;
    }
  }
  .homepage_header {
    width: 100%;
    height: 100px;
    background-color: #fff;

    @media screen and (max-width: 1500px) {
      height: 70px;
    }

    @media screen and (max-width: 768px) {
      height: 66px;
    }

    .homepage_header__wrapper {
      width: 90%;
      height: 100%;
      display: flex;
      margin: auto;
      position: relative;
      justify-content: space-between;

      @media screen and (max-width: 768px) {
        justify-content: center;
      }
      
      .homepage_header__left {
        display: flex;
        height: 100%;

        .homepage_header__logo {
          width: 400px;
          height: 52px;
          margin: auto 0;
          background-repeat: no-repeat;
          background-position-y: center;
          background-position-x: left;
          background-size: contain;
        
          @include themify($themes) {
            // background-image: themed('logoImgTr');
          }
          @media screen and (max-width: 1500px) {
            width: 300px;
            height: 39px;
          }
          @media screen and (max-width: 992px) {
            width: 250px;
            height: 33px;
          }
          @media screen and (max-width: 768px) {
            width: 200px;
            height: 26px;
          }
          @media screen and (max-width: 576px) {
            width: 170px;
            height: 22px;
          }
        }
      }

      .homepage_header__right {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        .homepage_social-media {
          .social-media__icon {
            color: #38B2C9;
          }
          @media screen and (max-width: 992px) {
            display: none;
          }
        }
        @media screen and (max-width: 767px) {
          display: none;
        }
      }

      .homepage__navbar {
        position: absolute;
        right: 0;
        padding-right: 0;
        z-index: 9;

        .navbar-toggler {
          margin-left: auto;
          display: flex;
          .navbar-toggler-icon{
            &:after{
              font-family: 'Font Awesome 5 Free';
              content: "\f0c9";
              color: #0367A8;
              font-weight: 900;
              font-size: 25px;
            }
          }
        }
        ul {
          @media screen and (max-width: 768px) {
            margin-top: 3px;
            background-color: #ffffff;
          }
        }
        .nav-item {
          a {
            color: $color-content;
            font-size: 16px;
            padding-right: 35px;
            padding-left: 35px;
            cursor: pointer;
            position: relative;

            a:last-child {
              padding-right: 0px;
            }
  
            @media screen and (max-width: 1500px) {
              font-size: 14px;
            }

            @media screen and (max-width: 768px) {
              font-size: 12px;
              padding: 12px 18px;
              -webkit-transition: all 0.3s;
              transition: all 0.3s;
              justify-content: center;
              text-align: center;
              cursor: pointer;
              &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 2px;
                background: $color-accent;
                opacity: 0;
                transition: all 0.3s;
              }
              &:hover {
                @include themify($themes) {
                  background-color: themed('colorHover');
                }
            
                &:before {
                  opacity: 1;
                }
              }
            }

          }
          
        }
      }
    }
    .homepage__header_navbar {
      display: none;
      @media screen and (max-width: 767px) {
        display: block;
        padding-top: 13px;
        color: #000000;
      }
    }
  }

  .homepage_slider {
    overflow-x: hidden!important;
  }
  .mouse__icon-wrap{
	display: flex;
	justify-content: center; /* Horizontal centering */
	align-items: center; 
	.mouse_icon img{
		height: var(--mouse-icon-height);
	}
  @media screen and (max-width: 768px) {
       display: none;
	  }
  }
   .homepage_social-media {
    display: flex;
    flex-direction: column;
    left: 100%;
    top: 50%;
    position: fixed;
    transform: translate(-50%, -50%);
    border: 1px solid black;
    padding-right: 50px;
    padding-top: 8px;
    padding-bottom: 8px;
    overflow-x: hidden;
    border-radius: 13px;

    .social-media__icon {
      margin: auto 0;
      margin-left: 20px;
      margin-top: 10px;
      font-weight: 900;
      font-family: 'Font Awesome 5 Brands';
      font-size: 23px;
      line-height: 23px;
      display: inline-block;

      @media screen and (max-width: 1500px) {
        font-size: 20px;
        line-height: 20px;
      }
      &:first-child {
        margin-top: 0;
      }
    }
    .social-media__icon_linkedin {
      &:after {
        content: "\f08c";
      }
    }
    .social-media__icon_facebook {
      &:after {
        content: "\f39e";
      }
    }
    .social-media__icon_twitter {
      &:after {
        content: "\f099";
      }
    }
    .social-media__icon_instagram {
      &:after {
        content: "\f16d";
      }
    }
  }


  .homepage_buttons {
    display: flex;
    .btn {
      padding: 7px 0px;
      border-radius: 30px;
      min-width: 140px;
      margin-bottom: 0px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 1500px) {
        min-width: 140px;
      }

      @media screen and (max-width: 1200px) {
        padding: 8px 0px;
        min-width: 130px;
      }

      @media screen and (max-width: 768px) {
        padding: 6px 0px;
        min-width: 120px !important;
      }
  
      &.btn-homepage {
        background-color: #38B2C9;
        border-color: #fff;

        p {
          color: #fff;
          font-size: 18px;

          @media screen and (max-width: 1500px) {
            font-size: 15px;
          }

          @media screen and (max-width: 1200px) {
            font-size: 13px;
          }

          @media screen and (max-width: 768px) {
            font-size: 12px;
          }
        }
  
        svg {
          width: 20px;
          height: 20px;

          @media screen and (max-width: 1500px) {
            width: 16px;
            height: 16px;
          }

          @media screen and (max-width: 1200px) {
            margin-top: 1px;
          }
        }
    
        &:before {
          color: #fff;
        }
    
        &:hover, &:focus, &:active, &:active:focus {
          background-color: darken(#38B2C9, 10%);
          color: #ffffff;

          p {
            color: #ffffff;
          }

          svg {
            fill: #ffffff;
          }
        }
      }

      &.btn-outline-homepage {
        border-color: #38B2C9;
        p {
          color: #4D4F5C;
          font-size: 15px;
          @media screen and (max-width: 1500px) {
            font-size: 15px;
          }
          @media screen and (max-width: 1200px) {
            font-size: 13px;
          }
          @media screen and (max-width: 768px) {
            font-size: 12px;
          }
        }
  
        svg {
          width: 20px;
          height: 20px;
          fill: #38B2C9;
          margin-right: 5px;
          &:nth-child(2) {
            margin-right: 0 !important;
          }
          
          @media screen and (max-width: 1500px) {
            width: 16px;
            height: 16px;
          }

          @media screen and (max-width: 1200px) {
            margin-top: 1px;
          }
        }

        &:hover, &:focus, &:active, &:active:focus {
          background-color: darken(#38B2C9, 10%);
          p {
            color: #ffffff;
          }

          svg {
            fill: #ffffff;
          }
        }
      }

      &.btn-homepage-dark {
        background-color: #0367A8;
        border-color: #0367A8;

        &:before {
          background-color: #0367A8;
        }
    
        &:hover, &:focus, &:active, &:active:focus {
          background-color: darken(#0367A8, 10%);
          color: #ffffff;

          p {
            color: #ffffff;
          }

          svg {
            fill: #ffffff;
          }
        }
      }

      &.btn-sm {
        padding: 5px 10px;
        min-width: 130px;
        max-width: 150px;
        border-radius: 15px;
        align-items: center;
        justify-content: center;
        @media screen and (max-width:330px){
          margin-right: 0px;
        }
        @media screen and (max-width: 1200px) {
          min-width: 120px;
          max-width: 120px;
        }
        @media screen and (max-width: 768px) {
          padding: 5px 12px;
          min-width: 100px !important;
        }
        @media screen and (max-width: 576px) {
          padding: 5px 7px;
          min-width: 90px !important;
          margin-right: 5px;
        }
        p {
          font-size: 13px;
          line-height: 13px;
          color: #fff;
          align-items: center;
          justify-content: center;

          @media screen and (max-width: 1500px) {
            font-size: 12px;
          }
        
          @media screen and (max-width: 768px) {
            font-size: 11px;
          }

          @media screen and (max-width: 576px) {
            font-size: 10px;
          }
        }
        svg {
          width: 15px;
          min-width: 15px;
          height: 15px;
          margin-right: 5px;
          margin-top: 0px;
          &:nth-child(2) {
            margin-right: 0 !important;
          }
          
          @media screen and (max-width: 1500px) {
            width: 13px;
            min-width: 13px;
            height: 13px;
          }
        }
      }
    }

    .dropdown__menu {
      width: 150px;
      padding: 0;
      left: auto !important;
      right: 0px !important;
      transform: translate3d(0px, 48px, 0px) !important;
      @media screen and (max-width: 1200px) {
        font-size: 12px;
      }

      @media screen and (max-width: 768px) {
        width: 140px;
        min-width: 140px;
        font-size: 11px;
        transform: translate3d(0px, 40px, 0px) !important;
      }
      
      .dropdown-item{
        padding: 14px 20px;
        transition: all 0.3s;
        width: 100%;
        justify-content: center;
        position: relative;
        cursor: pointer;
        text-align: center;
        color: $color-accent;

        i {
          font-size: 15px;
          margin-right: 10px;
          color: #4D565C;

          @media screen and (max-width: 768px) {
            font-size: 13px;
          }
        }

        @media screen and (max-width: 768px) {
          padding: 12px 16px;
        }

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 2px;
          background: $color-accent;
          opacity: 0;
          transition: all 0.3s;
        }
        &:hover {
          @include themify($themes) {
            background-color: themed('colorHover');
          }
      
          &:before {
            opacity: 1;
          }
        }
      }
    }
  }

  .homepage_slider {
    width: 100%;
    height: calc(100vh - calc(100px + var(--mouse-icon-height)));
    min-height: calc(100vh - calc(100px + var(--mouse-icon-height)));
    background-color: #fff;

    @media screen and (max-width: 1500px) {
      height: calc(100vh - 80px);
      min-height: calc(100vh - 80px);
    }
    
    @media screen and (max-width: 992px) {
      height: 500px;
      min-height: 500px;
    }

    .homepage_slider_background {
      width: 100%;
      height: 100%;
      display: flex;
      overflow-y: auto;
      background: url(../shared/img/homepage_slider_background.jpg) no-repeat center;
      background-size: cover;
      background-position: bottom;


      &.eng_slider {
        background: url(../shared/img/homepage_slider_background_eng.png) no-repeat center;
        background-size: cover;
        background-position: center;
        @media screen and (max-width : 768px) {
          height: 100% !important;
          background: url(../shared/img/homepage_mobile_slider_background.jpg) no-repeat center;
          background-position: top;
          background-size: unset;
        }
        
      }

      @media screen and (max-width: 768px) {
        height: 100% !important;
        background: url(../shared/img/homepage_mobile_slider_background.jpg) no-repeat center;
        background-position: top;
        background-size: unset;
      }
      @media screen and (max-width: 475px) {
        background-position: top;
      }
      @media screen and (max-width: 475px) {
        background-position: top;
      }
      @media screen and (max-width: 475px) {
        background-position: top;
      }
    }
    
    .homepage_slider__wrapper {
      width: 90%;
      height: 100px;
      display: flex;
      margin: 0 auto;
      position: relative;
  
      .homepage_slider__left{
        width: 700px;
        margin-top: 6%;
  
        @media screen and (max-width: 1500px) {
          width: 550px;
        }

        @media screen and (max-width: 1200px) {
          width: 510px;
        }

        @media screen and (max-width: 992px) {
          width: 490px;
          margin-top: 75px;
        }

        @media screen and (max-width: 768px) {
          width: 400px;
        }

        @media screen and (max-width: 576px) {
          width: 90%;
        }
  
        h1 {
          font-size: 40px;
          font-weight: 800;
          color: #161615;
          line-height: 53px;
          @media screen and (max-width: 1500px) {
            font-size: 32px;
          }

          @media screen and (max-width: 1200px) {
            font-size: 30px;
          }

          @media screen and (max-width: 992px) {
            font-size: 28px;
            line-height: 42px;
          }

          @media screen and (max-width: 768px) {
            font-size: 23px;
            line-height: 28px;
          }
        }
  
        h3 {
          font-size: 34px;
          font-weight: 500;
          color: #161615;
          @media screen and (max-width: 1500px) {
            font-size: 28px;
          }

          @media screen and (max-width: 1200px) {
            font-size: 26px;
          }

          @media screen and (max-width: 992px) {
            font-size: 24px;
          }

          @media screen and (max-width: 768px) {
            font-size: 21px;
          }
        }
        .homepage_slider__left_content{
          margin: 20px 0px;
          p{
            font-size: 18px;
            font-weight: 300;
            color: #000000;
            @media screen and (max-width: 1500px) {
              font-size: 14px;
            }
            @media screen and (max-width: 1200px) {
              font-size: 13px;
            }
            @media screen and (max-width: 768px) {
              font-size: 12px;
            }
          }
        }
        
      }
  
      .homepage__navbar {
        position: absolute;
        right: 0;
        padding-right: 0;

        @media screen and (max-width: 710px) {
          display: none;
        }

        .navbar-toggler {
          margin-left: auto;
          .navbar-toggler-icon{
            &:after{
              font-family: 'Font Awesome 5 Free';
              content: "\f0c9";
              color: #4D4F5C;
              font-weight: 900;
              font-size: 25px;
            }
          }
        }
        ul {
          @media screen and (max-width: 767px) {
            margin-top: 3px;
            background-color: #ffffff;
          }
        }
        .nav-item {
          a {
            color: $color-content;
            font-size: 16px;
            padding-right: 35px;
            padding-left: 35px;
            cursor: pointer;
            position: relative;

            a:last-child {
              padding-right: 0px;
            }
  
            @media screen and (max-width: 1500px) {
              font-size: 14px;
            }

            @media screen and (max-width: 767px) {
              font-size: 12px;
              padding: 12px 18px;
              -webkit-transition: all 0.3s;
              transition: all 0.3s;
              justify-content: center;
              text-align: center;
              cursor: pointer;
              &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 2px;
                background: $color-accent;
                opacity: 0;
                transition: all 0.3s;
              }
              &:hover {
                @include themify($themes) {
                  background-color: themed('colorHover');
                }
            
                &:before {
                  opacity: 1;
                }
              }
            }

          }
          &:last-child {
            a {
              padding-right: 3px;
            }
          }
        }
      }
    }
  }

  .homepage_what_can_you_do {
    width: 100%;
    display: flex;
    padding: 105px 0px 130px 0px;
    background-color: #F4F4F4;

    @media screen and (max-width: 992px) {
      padding: 80px 0px 100px 0px;
    }

    @media screen and (max-width: 992px) {
      padding: 60px 0px 80px 0px;
    }

    .container {
      width: 80%;

      @media screen and (max-width: 576px) {
        max-width: 250px; 
        width: 250px !important;
      }
    }

    .homepage-card {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      border: none;
      padding: 40px 15px;
  
      @media screen and (max-width: 1500px) {
        padding: 30px 10px;
      }

      &:hover {
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);

        @media screen and (max-width: 992px) {
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          -ms-transform: scale(1);
          -o-transform: scale(1);
        }
      }
  
      .homepage-card__img {
        width: 130px;
        margin: 0 auto;
        @media screen and (max-width: 1500px) {
          width: 120px;
        }
      }
  
      .homepage-card__title {
        font-family: 'Montserrat', sans-serif;
        font-size: 15px;
        color: #0B6BC0;
        font-weight: 700;
        text-align: center;
        margin-top: 30px;
  
        @media screen and (max-width: 1500px) {
          font-size: 13px;
        }
      }
    
      .homepage-card__content {
        font-family: 'Montserrat', sans-serif;
        font-size: 13px;
        font-weight: 500;
        color: #6D7783;
        text-align: center;
  
        @media screen and (max-width: 1500px) {
          font-size: 11px;
        }
      }
    }
  }

  .homepage_grid_title{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 70px;

    @media screen and (max-width: 576px) {
      margin-bottom: 55px;
    }

    h1 {
      font-family: 'Montserrat', sans-serif;
      font-size: 30px;
      font-weight: 800;
      color: #344154;
      text-align: center;
      margin-bottom: 10px;
      @media screen and (max-width: 1500px) {
        font-size: 26px;
      }

      @media screen and (max-width: 576px) {
        font-size: 22px;
      }
    }

    p {
      font-family: 'Montserrat', sans-serif;
      width: 1000px;
      color: #344154;
      font-size: 15px;
      font-weight: 300;
      text-align: center;
      margin: auto;
      line-height: 17px;
      @media screen and (max-width: 1500px) {
        width: 900px;
        font-size: 13px;
      }
      @media screen and (max-width: 1200px) {
        width: 80%;
      }
      @media screen and (max-width: 768px) {
        width: 90%;
        font-size: 12px;
      }
    }
  }

  .homepage_how_do_you_use {
    width: 100%;
    display: flex;
    padding: 105px 0px 120px 0px;
    background-color: #ffffff;

    @media screen and (max-width: 992px) {
      padding: 80px 0px 95px 0px;
    }

    @media screen and (max-width: 768px) {
      padding: 60px 0px 70px 0px;
    }

    .container {
      width: 80%;
      @media screen and (max-width: 576px) {
        max-width: 250px; 
        width: 250px !important;
      }
    }

    .homepage-card {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      border: none;
      padding: 40px 20px;
  
      @media screen and (max-width: 1500px) {
        padding: 30px 15px;
      }
  
      .homepage-card__img {
        width: 65px;
        margin: 0 auto;
        @media screen and (max-width: 1500px) {
          width: 55px;
        }
      }
  
      .homepage-card__title {
        font-size: 18px;
        font-family: 'Montserrat', sans-serif;
        color: #222A41;
        font-weight: 500;
        text-align: center;
        margin-top: 15px;  
        @media screen and (max-width: 1500px) {
          font-size: 16px;
        }
      }
    
      .homepage-card__content {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 400;
        min-height: 100px;
        color: #6D7783;
        text-align: center;
  
        @media screen and (max-width: 1500px) {
          font-size: 12px;
        }

        @media screen and (max-width: 768px) {
          font-size: 11px;
        }
      }
      .homepage-card__arrow-img{
        width: 28px;
        position: absolute;
        bottom: 0;
        left: 45%;
      }
    }
  }

  .homepage_job_postings {
    width: 100%;
    display: flex;
    background-color: #EDF6FF;
    padding: 50px 0px 100px 0px;

    @media screen and (max-width: 992px) {
      padding: 40px 0px 80px 0px;
    }

    @media screen and (max-width: 768px) {
      padding: 40px 0px 60px 0px;
    }
  
    .container {
      width: 70%;
      @media screen and (max-width: 576px) {
        max-width: 250px; 
        width: 250px !important;
      }
    }
    .slick-slider {
      width: 100%;
      padding: 0px 80px;

      @media screen and (max-width: 768px) {
        padding: 0px 30px;
      }

      .slick-prev, .slick-next {
        width: 13px;
        height: 23px;
      }

      .slick-prev {
        left: 0;
      }
      .slick-next {
        right: 0;
      }

      .slick-slide {
        & > div {
          display: flex;
        }
      }
    }
    .jobposting__card {
      height: 100%;
      border-radius: 5px;
      background-color: #ffffff;
      padding: 0;
      -webkit-box-shadow: 0px 2px 8px 3px rgba(0,0,0,0.16); 
      box-shadow: 0px 2px 8px 3px rgba(0,0,0,0.16);

      .jobposting__information{
        display: flex;
        padding: 28px 18px;
        align-items: center;

        @media screen and (max-width: 576px) {
          flex-direction: column;
        }

        .jobposting__avatar{
          width: 88px;
          min-width: 88px;
          height: 88px;
          border-radius: 88px;
          border: 1px solid #707070;
          padding: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 15px;
          @media screen and (max-width: 576px) {
            margin-right: 0px;
            margin-bottom: 10px;
          }
        }
      }


      .jobposting__data {
        @media screen and (max-width: 576px) {
          text-align: center;
        }
        .jobposting__company{
          font-size: 14px;
          font-weight: 900;
          color: #344154;
        }
        .jobposting__work_desc{
          font-size: 11px;
          font-weight: 900;
          color: #344154;
        }
        .jobposting__work_place{
          font-size: 9px;
          font-weight: 900;
          color: #344154;
          margin: -1px 0 10px 0;
        }
      }

      .jobposting__btn {
        padding: 4px 13px;
        border-radius: 30px;
        margin-bottom: 0px;
        background-color: #459EFF;
        border-color: #459EFF;

        p {
          color: #fff;
          font-size: 9px;
          font-weight: 900;
        }
    
        &:hover, &:focus, &:active, &:active:focus {
          background-color: darken(#38B2C9, 10%);
          color: #ffffff;

          p {
            color: #ffffff;
          }

          svg {
            fill: #ffffff;
          }
        }
      }

      .jobposting_star{
        width: 30px;
        height: 30px;
        background: url(../shared/img/homepage_jobposting_star.png) no-repeat center;
        background-size: cover;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  .homepage_statics {
    width: 100%;
    display: flex;
    background-color: #EDF6FF;
    padding: 110px 0px 150px 0px;

    @media screen and (max-width: 992px) {
      padding: 95px 0px 125px 0px;
    }

    @media screen and (max-width: 768px) {
      padding: 75px 0px 100px 0px;
    }

    .container {
      width: 80%;
      @media screen and (max-width: 576px) {
        max-width: 250px; 
        width: 250px !important;
      }
    }

    .homepage-card {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      border: none;
      padding: 40px 20px;
      background: transparent;
  
      @media screen and (max-width: 1500px) {
        padding: 30px 15px;
      }

      @media screen and (max-width: 992px) {
        padding: 20px 15px;
      }
  
      .homepage-card__img {
        height: 105px;
        margin: 0 auto 15px auto;
        @media screen and (max-width: 1500px) {
          height: 90px;
        }
        @media screen and (max-width: 992px) {
          height: 75px;
        }
        @media screen and (max-width: 768px) {
          height: 65px;
        }
      }
  
      .homepage-card__title {
        font-family: 'Montserrat', sans-serif;
        font-size: 30px;
        color: #344154;
        font-weight: 800;
        text-align: center;
  
        @media screen and (max-width: 1500px) {
          font-size: 28px;
        }
        @media screen and (max-width: 992px) {
          font-size: 26px;
        }
        @media screen and (max-width: 768px) {
          font-size: 22px;
        }
      }
    
      .homepage-card__content {
        font-family: 'Montserrat', sans-serif;
        font-size: 30px;
        font-weight: 300;
        color: #344154;
        text-align: center;
        line-height: 25px;
        @media screen and (max-width: 1500px) {
          font-size: 28px;
        }
        @media screen and (max-width: 992px) {
          font-size: 26px;
        }
        @media screen and (max-width: 768px) {
          font-size: 22px;
        }
      }
    }
  }

  .homepage_logo_slider {
    width: 100%;
    display: flex;
    padding: 60px 0px 40px 0px;
    background-color: #fff;

    .slick-slider {
      width: 100%;

      .slick-track {
        display: flex;
        align-items: center;
      }
    }
    .container {
      width: 70%;
      @media screen and (max-width: 576px) {
        max-width: 250px; 
        width: 250px !important;
      }
    }

    .homepage_logo_slider_img_wrapper{
      width: auto !important;
    }
  }
}

.homepage_footer {
  width: 100%;
  padding: 85px 0px 90px 0px;
  background-color: #38B2C9;

  .homepage_footer__wrapper {
    width: 60%;
    height: 100%;
    display: flex;
    margin: auto;
    position: relative;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .homepage_footer__left {
      display: flex;
      height: 100%;
      padding-right: 40px;

      @media screen and (max-width: 768px) {
        padding-right: 0px;
      }

      .homepage_footer__logo {
        width: 267px;
        height: 54px;
        margin: auto 0;
        background-repeat: no-repeat;
        background-position-y: center;
        background-position-x: left;
        background-size: contain;
        // background-image: url(../shared/img/yekbeyaz.png);

        @media screen and (max-width: 1500px) {
          width: 200px;
          height: 40px;
        }

        @media screen and (max-width: 768px) {
          width: 267px;
          height: 54px;
        }
      }
    }
    .homepage_footer__right {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 50px;
      border-left: 1px solid #fff;
      position: relative;

      @media screen and (max-width: 768px) {
        padding: 0;
        border-left: none;
      }

      .homepage__navbar {
        display: flex;  
        padding: 0;

        ul {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          @media screen and (max-width: 1200px) {
            flex-wrap: inherit;
          }
          
          @media screen and (max-width: 768px) {
            flex-direction: column;
            text-align: center;
          }
        }
        .navbar-toggler-icon{
          &:after{
            font-family: 'Font Awesome 5 Free';
            content: "\f0c9";
            color: #fff;
            font-weight: 900;
            font-size: 25px;
          }
        }
        .nav-item {
          a {
            color: #ffffff;
            font-size: 16px;
            cursor: pointer;
  
            @media screen and (max-width: 1500px) {
              font-size: 14px;
            }
          }
        }
      }
      .homepage_footer__contact {
        display: flex;
        margin-top: 40px;
        flex-direction: column;
        
        @media screen and (max-width: 768px) {
          justify-content: center;
        }
        ul {
          padding: 0;

          li {
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;
            @media screen and (max-width: 768px) {
              text-align: center;
            }
          }
        }
        .homepage_footer__contact_title {
          font-size: 11px;
          font-weight: 700;
          color: #fff;
          @media screen and (max-width: 1500px) {
            font-size: 10px;
          }
          @media screen and (max-width: 768px) {
            font-size: 11px;
          }
        }

        .homepage_footer__contact_content {
          font-size: 11px;
          font-weight: 400;
          color: #fff;
          @media screen and (max-width: 1500px) {
            font-size: 10px;
          }
          @media screen and (max-width: 768px) {
            font-size: 11px;
            text-align: center;
          }
          .support_mail{
            font-weight: 700;
          }
        }
      }

      .homepage_social-media {
        position: relative;
        margin-top: 20px;

        .social-media__icon {
          color: #fff;
          margin-left: 30px;

          &:first-child {
            margin-left: 0px;
          }
        }

        @media screen and (max-width: 768px) {
          margin: auto;
          margin-top: 20px;
        }
      }
      
    }
  }
}

.homepage-modal-dialog {
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;

  @media screen and (max-width: 1500px) {
    max-width: 500px;
  }

  @media screen and (max-width: 1200px) {
    max-width: 350px;
  }

  @media screen and (max-width: 576px) {
    max-width: 80%;
  }

  .modal-content {
    padding: 0px;
  }
}

.homepage__navbar_lg {
  @extend .homepage__navbar;
  position: relative!important;
  @media screen and (max-width: 1080px) {
    display: none;
  }

  @media screen and (max-width: 1200px){
    .nav-item {
      .nav-link {
        font-size: 12px!important;
        padding-right: 4px!important;
        padding-left: 4px!important;
      }
    }
  }
  .nav-item {
    display: flex;
    align-items: center;
    .nav-link {
      padding-right: 10px!important;
      padding-left: 10px!important;
    }
  }
}

@media screen and (max-width: 1080px) {
  .homepage .homepage_buttons .btn {
    min-width: 120px!important;
    font-size: 10px!important;
  }
}
