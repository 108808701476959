$themes: (
  light: (
    colorBackground: white,
    colorBackgroundBody: #F0F0F7,
    colorText: #4D4F5C,
    colorText2: #43425D,
    colorTextAdditional: #646777,
    logoImg: url(../shared/img/yekEn.png),
    logoImgTr: url(../shared/img/yek.png),
    colorHover: #fafbfe,
    colorBorder: #eff1f5,
    colorIcon: #dddddd,
    imgInvert: invert(0%),
    colorFieldsBorder: #E9E9F0,
    colorBubble: rgba(242, 244, 247, 0.65),
    colorBubbleActive: rgba(234, 238, 255, 0.6),
    colorScrollbar: #B4BFD0,
    colorFitness: #646777,
  ),
  dark: (
    colorBackground: #232329,
    colorBackgroundBody: #2a2a31,
    colorText: #dddddd,
    colorTextAdditional: #999999,
    logoImg: url(../shared/img/yekEn.png),
    logoImgTr: url(../shared/img/yek.png),
    colorHover: #38373f,
    colorBorder: #333246,
    colorIcon: #605f7b,
    imgInvert: invert(100%),
    colorFieldsBorder: #33333a,
    colorBubble: rgba(68, 79, 97, 0.65),
    colorBubbleActive: rgba(92, 104, 156, 0.6),
    colorScrollbar: #606071,
    colorFitness: #ffffff
  )
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}


$color-accent2: #5F4C4E;
$color-accent2-hover: darken($color-accent2, 10%);

$color-accent: #70bbfd;
$color-accent-hover: darken($color-accent, 10%);
$color-additional: #999999;
$color-additional-hover: darken($color-additional, 10%);

$color-yellow: #f6da6e;
$color-yellow-hover: darken($color-yellow, 10%);

$color-violet: #c88ffa;

$color-red: #ff4861;
$color-red-hover: darken($color-red, 10%);

$color-blue: #70bbfd;
$color-blue-hover: darken($color-blue, 10%);

$color-gray: #787985;
$color-light-gray: #d8dfe9;

$color-green: #b8e986;
$color-green-hover: darken($color-green, 10%);



$color-accent: #38B2C9;
$color-accent-dark: #0367A8;
$color-transparent: transparent;

$color-content: #4D4F5C;
$color-content2: #252322;

// Form Color
$color-input-border: #E9E9F0;
$color-input-placeholder: #CECFD0;
$color-checkbox-border: #808495;
$color-radio-border: #D6D6D7;
$color-selectbox-arrow: #A4AFB7;
$color-icon: #5F4C4E;

// Button Color
$color-button-primary: #005389;
$color-button-primary-border: #4F4D5C;

// Login Color
$color-login-tab: #38B2C942;

// Register Color
$color-register-title: #2B284B;
$color-register-form-label: #43425D;

// Topbar
$color-topbar-icon: #4D565C;
$color-topbar-language-icon: #BCBCCB;
$color-topbar-divider: #F1F1F3;
$color-menu-left-border: #EBEBF2;

// Sidebar
$color-collapse-button: #32A0B5;
$color-sidebar-link-left: #43425D;
$color-sidebar-link-hover: #32A0B5;

// Dashboard
$dashboard-content-color: #778290;



$directions: (
  ltr: (
    direction: ltr,

    right: right,
    left: left,

    margin-right: 'margin-right',
    margin-left: 'margin-left',

    padding-left: 'padding-left',
    padding-right: 'padding-right',

    border-top-left-radius: 'border-top-left-radius',
    border-bottom-left-radius: 'border-bottom-left-radius',

    border-top-right-radius: 'border-top-right-radius',
    border-bottom-right-radius: 'border-bottom-right-radius',

    border-left: 'border-left',
    border-right: 'border-right',

    translate: 'translate(-50%, -50%);',
    mirrorY: 'scale(1, 1)',

    border-right-color: 'border-right-color',

    transform-sidebar: 'rotate(90deg)',

    flex-flow: row nowrap,
    row: 'row',

    sidebar-close: translateX(0),
    sidebar-no-desktop: translateX(calc(0%)),
    '-': '-',
  ),
  rtl: (
    direction: rtl,

    right: left,
    left: right,

    margin-right: 'margin-left',
    margin-left: 'margin-right',

    padding-left: 'padding-right',
    padding-right: 'padding-left',

    border-top-left-radius: 'border-top-right-radius',
    border-bottom-left-radius: 'border-bottom-right-radius',

    border-top-right-radius: 'border-top-left-radius',
    border-bottom-right-radius: 'border-bottom-left-radius',

    border-left: 'border-right',
    border-right: 'border-left',

    translate: 'translate(50%, -50%);',
    mirrorY: 'scale(1, -1)',

    border-right-color: 'border-left-color',
    transform-sidebar: 'rotate(-90deg)',

    flex-flow: row-reverse nowrap,
    row: 'row-reverse',

    sidebar-close: translateX(0),
    sidebar-no-desktop:  translateX(calc(100%)),
    '-': '+',
  )
);
@mixin directify($directions) {
  @each $direction, $map in $directions {
    .#{$direction}-support & {
      $direction-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($directions, $direction), '#{$key}');
        $direction-map: map-merge($direction-map, ($key: $value)) !global;
      }
      @content;
      $direction-map: null !global;
    }
  }
}

@function directed($key) {
  @return map-get($direction-map, $key);
}