.popover {
  box-shadow: 0 2px 4px 0 #00000012;
  border: solid 1px #e8ebef;
  border-radius: 4px;
  max-width: 60vw;
  z-index: 1060;

  @include themify($themes) {
    background-color: themed('colorBackground');
  }

  // .popover-header {
  //   border: none;
  //   background-color: #f2f4f7;
  //   font-weight: 700;
  //   font-size: 11px;
  //   padding: 5px 10px;
  //   line-height: normal;
  // }

  // .popover-body {
  //   font-size: 8px;
  //   @include directify($directions) {
  //     #{directed('padding-right')}: 15px;
  //     #{directed('padding-left')}: 10px;
  //   }
  //   padding-top: 10px;
  //   padding-bottom: 10px;
  // }
}