.login__wrapper{
  background-color: $color-transparent;
  // margin: auto 8%;
  margin: auto 0% 4% 8%;
  font-family: 'Montserrat', sans-serif;

  @media screen and (max-width: 1900px) {
    margin: auto 0% 3% 8%;
  }

  @media screen and (max-width: 576px) {
    margin: auto;
  }

  @media screen and (max-height: 730px) {
    margin-top: 90px;
  }
  .col-12 .col-md-12 .col-lg-12 {
    padding: 0;
  }
  .tabs {
    overflow: hidden;
    &.tabs--justify {
      width: 100% !important;
    }
  }

  .card{
    width: 480px;

    @media screen and (max-width: 1900px) {
      width: 440px;
    }

    @media screen and (max-width: 1500px) {
      width: 365px;
    }

    @media screen and (max-width: 992px) {
      width: 330px;
    }

    @media screen and (max-width: 576px) {
      margin: auto;
    }
  }

  .card-header {
    background-color: $color-transparent;
    border-bottom: 0px;
    width: 100%;
    padding: 0;
    margin-bottom: 40px;

    @media screen and (max-width: 1500px) {
      margin-bottom: 25px;
    }
  }

  .card-body {
    padding: 0px;
    background-color: $color-transparent;
  }

  .tabs__wrap {
    display: inline;

    .nav-tabs {
      background-color: $color-transparent;
      border-bottom: 4px solid $color-login-tab;

      @media screen and (max-width: 1900px) {
        border-bottom: 3px solid $color-login-tab;
      }

      .nav-item {
        margin-bottom: -3px;
        .login-tab-img {
          color: $color-accent-dark;

          svg {
            @media screen and (max-width: 1500px) {
              width: 20px;
              height: 20px;
            }
          }
        }
      }

      .nav-link.active {
        border-bottom: 4px solid $color-accent !important;
        padding-right: 20px !important;

        @media screen and (max-width: 1900px) {
          border-bottom: 3px solid $color-accent !important;
        }
      }
      .nav-link {
        font-size: 16px;
        &:hover {
          border-bottom: 0px;
        }
        @media screen and (max-width: 1900px) {
          font-size: 14px;
        }
        @media screen and (max-width: 1500px) {
          font-size: 12px;
        }
      }
    }
  }

  .tab-content {
    .tab-pane {
      padding-top: 40px;

      @media screen and (max-width: 1500px) {
        padding-top: 25px;
      }
      .account__card {
        background-color: $color-transparent;
        padding: 0px;
        opacity: 1;

        .account__head {
         @media screen and (max-width: 1500px) {
            margin-bottom: 15px;
          }
        }

        .account__title {
          font-weight: 800;
          color: $color-accent-dark;
          font-size: 24px;
          @media screen and (max-width: 1900px) {
            font-size: 22px;
          }
          @media screen and (max-width: 1500px) {
            font-size: 20px;
          }
        }
        .form__form-group{

          @media screen and (max-width: 1500px) {
            margin-bottom: 15px;
          }

          .form__form-group-label {
            margin-bottom: 0px;
            font-size: 13px;
            font-weight: 300;
            color: $color-content2;
            @media screen and (max-width: 1900px) {
              font-size: 11px;
            }
            @media screen and (max-width: 1500px) {
              font-size: 10px;
            }
          }
  
          .form__form-group-input-wrap input {
            background: transparent;
            border: 0;
            border-bottom: 2px solid $color-input-border;
            padding: 0px 10px;
          }
        }
        .login__forgot-password {
          font-size: 15px;
          font-weight: 500;
          a {
            color: $color-accent-dark;
        
            &:hover {
              text-decoration: underline;
              color: #003456;
            }
          }
          @media screen and (max-width: 1900px) {
            font-size: 12px;
          }
          @media screen and (max-width: 1500px) {
            font-size: 10px;
          }
        }

        .checkbox-btn__checkbox-custom {
          width: 16px;
          height: 16px;
          border: 1px solid $color-checkbox-border;
          svg {
            height: 14px;
            width: 14px;
            margin-top: -9px;
            fill: $color-accent;
          }
        }

        .checkbox-btn__label{
          font-size: 14px;
          font-weight: 300;
          color: $color-content2;
          padding-left: 26px;
          padding-top: 0px;
          @media screen and (max-width: 1900px) {
            font-size: 13px;
          }
          @media screen and (max-width: 1500px) {
            font-size: 11px;
          }
        }
        
        .login-register {
          margin-left: auto;
          order: 2;
          font-size: 13px;
          font-weight: 300;
          a {
            color: $color-content2;
          }
          @media screen and (max-width: 1500px) {
            font-size: 12px;
          }
        }
        .form__form-group-button {
          background: transparent;
          border: 0px;
          color:$color-icon 
        }
        .account__btns {
          padding: 0px;
          margin: 0;
          width: 100%;
        }
      }
    }
  }
  .account__subhead{
    font-size: 14px;
    font-weight: 300;
    color: $color-content2;
    text-align: justify;
    margin-top: 5px;
    @media screen and (max-width: 1900px) {
      font-size: 12px;
    }
    @media screen and (max-width: 1500px) {
      font-size: 11px;
    }
    .support_mail{
      font-weight: 700;
    }
  }
}

.login-copyright {
  font-size: 15px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  color: rgba(0, 0, 0, 0.6) !important;
  margin-top: -50px;
  @media screen and (max-width: 1900px) {
    font-size: 13px;
  }
  @media screen and (max-width: 1500px) {
    font-size: 12px;
  }
  @media screen and (max-width: 992px) {
    position: relative;
    display: flex;
    text-align: center;
    margin: auto;
    justify-content: center;
    margin-top: 20px;
  }
}
/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}