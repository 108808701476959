.survey-form-col{
  .btn-toolbar {
    margin-top: 0;
    margin-left: 10px;
    .btn-group {
      .btn{
        padding: 5px 6px;
        height: 30px;
        width: 30px;
      }
    }
  }
}
.survey-toolbar {
  padding: 0;
  border-left: 1px solid #eff1f5;
}
.survey-toolbar .form__button-toolbar {
  border-bottom: 1px solid #eff1f5;
  justify-content: center;
  padding: 15px 0px;
  margin: 0;
}
.survey-toolbar .form__button-toolbar button{
  margin-bottom: 0px;
}
.survey-toolbar-form {
  padding: 15px 30px;
}
.survey-toolbar-form-question-group{
  margin: 0;
  margin-bottom: 10px;
}
.survey-toolbar-form-option-group{
  margin: 0;
  margin-bottom: 3px;
}
.survey-toolbar-form .btn-toolbar {
  margin-top: 0;
  margin-left: 10px;
}
.survey-toolbar-form-submit-button{
  width: 100%;
  padding: 15px 0px;
  border-top: 1px solid #eff1f5;
}