
.register-topbar{
  position: relative;
  height: 80px;
  background-color: $color-accent !important;
  display: flex;
  justify-content: center;
  align-items: center;

  .register-topbar-logo {
    width: 320px;

    @media screen and (max-width: 768px) {
      width: 280px;
    }

    @media screen and (max-width: 576px) {
      width: 200px;
    }
  }

  .go-back-button-toolbar {
    margin: auto 25px;
    position: absolute;
    left: 0;

    @media screen and (max-width: 576px) {
      margin: auto 15px;
    }
    .back_button {
      font-size: 16px;

      @media screen and (max-width: 576px) {
        font-size: 14px;
      }

      b {
        color: #ffffff;
        margin-left: -10px;
      }
    }
    .arrow__icon {
      fill: #fff !important;

      @media screen and (max-width: 576px) {
        width: 42px;
        height: 52px;
      }
    }
  }
}

.register-account {
  height: auto !important;
  // min-height: calc(100vh - 133px) !important;
  min-height: 100vh !important;
  overflow-y: inherit;
  @media screen and (max-width: 1500px) {
    // min-height: calc(100vh - 128px) !important;
    min-height: 100vh !important;
  }
}

.account--register-photo{
  background: url(../shared/img/student_register.jpg) no-repeat center !important;
  background-size: cover !important;
}

.account--register-photo2 {
  background: url(../shared/img/company_register.jpg) no-repeat center !important;
  background-size: cover !important;
}

.account--white-background {
  background: #fff !important;
}

.register__wrapper {
  margin: 0px auto !important;

  span, p, h1, h2, h3, h4, h5, h6 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;

    &.light {
      font-weight: 300
    }

    &.semibold {
      font-weight: 600
    }

    &.bold {
      font-weight: 700
    }
  }

  .form__form-group {
    margin-bottom: 20px;

    &.student_register_form_group{
      width:130%;
      .checkbox-btn  {
        margin-top: 0.5rem;
      }

    }
    &.company_register{
      width: 120%!important;

      .checkbox-btn  {
        margin-top: 4px;
      }
    }
  }

  .account__card {
    padding: 70px 60px;
    background-color: $color-transparent !important;
    opacity: 1;
    max-width: 580px;
    @media screen and (max-width: 1500px) {
      max-width: 520px;
      padding: 65px 60px;
    }
    @media screen and (max-width: 576px) {
      padding: 50px 30px;
    }
    @media screen and (max-width: 370px) {
      padding: 50px 20px;
    }
  }

  .register-title {
    font-size: 38px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    color: $color-register-title;
    text-align: center;
    margin-bottom: 65px;

    @media screen and (max-width: 1500px) {
      font-size: 36px;
      margin-bottom: 60px;
    }

    @media screen and (max-width: 768px) {
      font-size: 32px;
      margin-bottom: 50px;
    }
  }

  .card__title {
    margin-bottom: 20px;
  }

  .card__title:not(:first-child) {
      margin-top: 15px !important;
  }

  .register_subtitle {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: $color-register-title;
    text-transform: initial;

    @media screen and (max-width: 1500px) {
      font-size: 14px;
    }
  }

   .register_sms_mail_permission_title{
  margin-bottom: 10px;
  @media screen and(max-width: 768px) {
    max-width: 300px;
  }
  }
  .register_buttons {
    @media screen and(max-width: 768px) {
      max-width: 300px;
    }
  }

  .form.form--horizontal .form__form-group {
    flex-direction: initial;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .form__form-group-label {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 13px;
    color: $color-register-form-label;

    @media screen and (max-width: 1500px) {
      font-size: 12px;
    }
  }

  .form__form-group-input-wrap input {
    background: transparent;
    border: 1px solid $color-input-border;
    font-size: 13px;

    @media screen and (max-width: 1500px) {
      font-size: 12px;
    }

    &::-webkit-input-placeholder {
      font-family: 'Source Sans Pro', sans-serif;
      color: $color-input-placeholder;
    }
    &::-moz-placeholder {
      font-family: 'Source Sans Pro', sans-serif;
      color: $color-input-placeholder;
    }
    &:-moz-placeholder {
      font-family: 'Source Sans Pro', sans-serif;
      color: $color-input-placeholder;
    }
    &:-ms-input-placeholder {
      font-family: 'Source Sans Pro', sans-serif;
      color: $color-input-placeholder;
    }
  }

  .form__form-group-field {
    &.column{
      flex-direction: column !important;
    }
  }

  .form__form-group-field2  {
    input {
      background: transparent;
      border: 0;
      border-bottom: 2px solid #E9E9F0;
      padding: 0px 10px;
    }
  }

  .form__form-group-button {
    background: transparent;
    border: 0px;
    color:$color-icon;
  }



  .button-form-group-field {
    flex-direction: row !important;
  }

  .form__form-group-description{
    font-size: 13px !important;
    color: #bcbdbe;
    margin-left: 0 !important;
    @media screen and (max-width: 1500px) {
      font-size: 12px !important;
    }
  }

  .radio-btn__label{
    font-size: 12px;
    font-family: 'Source Sans Pro', sans-serif;
    color: $color-register-form-label;
    padding-top: 0px;
    @media screen and (max-width: 1500px) {
      font-size: 11px;
    }
  }

  .term-field-desc-label {
    width: 100% !important;
    font-size: 13px;
    button {
      font-size: 13px;
      color: $color-accent;
      display: contents;

      span {
        color: $color-accent;
      }
    }
  }

  .term-field-checkbox-label {
    width: auto !important;
    @media screen and (max-width: 576px) {
      width: 85% !important;
    }
    margin-top: 30px!important;
  }

  .term-field-label{
    width: 100% !important;
  }

  .btn-toolbar {
    margin-top: 10px;
  }
}

.register-copyright {
  margin-top: 0px !important;
}

.register-footer {
  width: 100%;
  height: 25px;
  background-color: $color-register-form-label;
  display: flex;
  margin-top: 60px;

  @media screen and (max-width: 1500px) {
    height: 20px;
  }
}