.btn {
  border-radius: 5px;
  padding: 9px 28px;
  //padding: 9px 25px;
  margin-bottom: 20px;
  margin-right: 14px;
  transition: all 0.4s;
  font-size: 15px;
  position: relative;
  overflow: hidden;
  z-index: 0;

  @media screen and(max-width:1500px){
    padding: 8px 28px;
    font-size: 13px;
  }

  &.btn-sm {
    padding: 5px 25px;
    font-size: 14px;
    @media screen and(max-width:1500px){
      font-size: 13px;
    }
    @media screen and (max-width:330px){
      margin-right: 0px;
    }
    &.btn-aligned-right {
      margin-left: auto;
    }
  }
  &.btn-xs {
    padding: 1px 5px;
    font-size: 14px;
    @media screen and(max-width:1500px){
      font-size: 13px;
    }
    @media screen and (max-width:330px){
      margin-right: 0px;
    }
    &.btn-aligned-right {
      margin-left: auto;
    }
  }

  &.btn-lg {
    padding: 13px 45px;
    font-size: 15px;

    @media screen and(max-width:1500px){
      font-size: 14px;
      padding: 11px 42px;
    }

    @media screen and(max-width:576px){
      font-size: 13px;
      padding: 12px 38px;
    }

    @media screen and(max-width:500px){
      padding: 10px 25px;
    }

    svg {
      margin: 0px 5px 0 0;
    }
  }

  &.btn-full {
    width: 100%;
    margin: 0;
  }

  &.btn-half {
    width: 48%;
    margin-right: 0;
    &:last-child {
      margin-left: auto;
    }
  }

  &.disabled {
    opacity: 0.5;
    &:hover, &:focus, &:active, &:active:focus {
      pointer-events: none;
    }
    svg {
      pointer-events: none;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &:before {
    position: absolute;
    height: 0;
    width: 0;
    border-radius: 50%;
    background-color: $color-accent;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
    transform: translate(-50%, -50%);
    z-index: -1;
    content: "";
    top: 0;
    left: 0;
  }

  p {
    display: flex;
    transition: all 0.3s;
    font-weight: 500;
    color: #444444;
  }

  svg {
    height: 14px;
    width: 14px;
    margin: 3px 5px 0 0;
    transition: all 0.3s;
    fill: #ffffff;
  }

  &:hover, &:focus, &:active, &:active:focus {
    outline: none;
    box-shadow: none !important;

    &:before {
      height: 500%;
      width: 225%;
    }
  }

  &:focus, &:active, &:active:focus {

    &:before {
      transition: all 0s;
    }
  }

  &.square {
    border-radius: 0;
  }

  &.rounded {
    border-radius: 30px !important;
  }

  &.icon {
    // padding: 10px 15px;
    /*
    &:hover {

      p {
        color: #646777;
      }

      svg {
        fill: #646777;
      }
    } */

    &.icon--right {

      svg {
        margin: 0px 0 0 5px;
      }
    }
  }

  &.icon-less-padding {
    padding: 5px 15px;
  }

  &.btn-primary {
    background-color: $color-button-primary;
    border-color: $color-button-primary-border;
    //display: none;
    @media screen and(max-width:330px){
      margin-right: 0px;
    }
    &:before {
      background-color: #04285e;
    }
  }

  &.btn-primary2 {
    background-color: $color-button-primary;
    border-color: $color-button-primary-border;
    display: none;
    @media screen and(max-width:330px){
      margin-right: 0px;
    }
    &:before {
      background-color: #04285e;
    }
  }

  &.btn-outline-primary {
    border-color: $color-button-primary-border;
    color: #005389;

    p {
      color: $color-button-primary-border;
    }

    svg {
      fill: $color-button-primary-border;
    }

    &:before {
      background-color: #04285e;
    }
  }

  &.btn-secondary {
    background-color: $color-button-primary-border;
    border-color: $color-button-primary-border;
    @media screen and(max-width:330px){
      margin-right: 0px;
    }
    &:before {
      background-color: #04285e;
    }
  }

  &.btn-outline-secondary {
    border-color: $color-button-primary-border;

    p {
      color: $color-content;
    }

    svg {
      fill: $color-content;
    }

    &:before {
      background-color: $color-button-primary-border;
    }
  }
  &.btn-outline-info {
    border-color: #5bc0de;

    p {
      color: #5bc0de;
    }

    svg {
      fill: #5bc0de;
    }

    &:before {
      background-color: #5bc0de;
    }
  }

  &.btn-success {
    background-color: $color-accent;
    border-color: $color-accent;

    &:before {
      background-color: $color-accent-hover;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: $color-accent-hover;
    }
  }

  &.btn-outline-success {
    color: $color-accent;
    border-color: $color-accent;

    p {
      color: $color-accent;
    }

    svg {
      fill: $color-accent;
    }

    &:before {
      background-color: $color-accent;
    }
  }

  &.btn-green {
    background-color: #28a745;
    border-color: #28a745;

    &:before {
      background-color: #2ec451;
    }
    p {
      color: #fff;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: #2ec451;
      background-color: #2ec451;
    }
  }

  &.btn-grey {
    background-color: #36353517;
    border-color: #36353517;

    &:before {
      background-color: #3635351c;
    }
    p {
      color: #fff;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: #3635351c;
      background-color: #3635351c;
    }
  }

  &.btn-outline-green {
    border-color: #28a745;
    color: #28a745;
    p {
      color: #28a745;
    }

    svg {
      fill: $color-accent;
    }

    &:before {
      background-color: #28a745;
    }
    &:hover, &:focus, &:active, &:active:focus {
      background-color: #28a745;
    }
    &.active {
      background-color: #28a745 !important;
      color: white !important;
    }
  }

  &.btn-warning {
    background-color: $color-yellow;
    border-color: $color-yellow;
    color: #ffffff;

    &:before {
      background-color: $color-yellow-hover;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: $color-yellow-hover;
    }
  }

  &.btn-outline-warning {
    color: $color-yellow;
    border-color: $color-yellow;

    p {
      color: $color-yellow;
    }

    svg {
      fill: $color-yellow;
    }

    &:before {
      background-color: $color-yellow;
    }
  }

  &.btn-danger {
    background-color: $color-red;
    border-color: $color-red;

    &:before {
      background-color: $color-red-hover;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: $color-red-hover;
    }
  }

  &.btn-outline-danger {
    color: $color-red;
    border-color: $color-red;

    p {
      color: $color-red;
    }

    svg {
      fill: $color-red;
    }

    &:before {
      background-color: $color-red;
    }
  }

  &.btn-transparent {
    background-color: transparent;
    border-color: black;

    &:before {
      background-color: black;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: black;
    }
  }

  &.btn-outline-transparent {
    color: black;
    border-color: black;

    p {
      color: black;
    }

    svg {
      fill: #ffffff42;
    }

    &:hover, &:focus, &:active, &:active:focus, &::before {
      background-color: #ffffff13;
    }
  }

  &.btn-light {
    &:hover, &:focus, &:active, &:active:focus, &::before {
      background-color: #36353517;
    }
  }

  &.btn-table-icons {
    background-color: #fff;
    border-color: #EFEFF6;
    display: block;

    &:before {
      background-color: #EFEFF6;
    }
  }

  &.btn-table-icons2 {
    background-color: #fff;
    border-color: #EFEFF6;
    display: none;

    &:before {
      background-color: #EFEFF6;
    }
  }

  &.btn-primary, &.btn-danger, &.btn-warning, &.btn-success {
    p {
      color: #ffffff;
    }

    svg {
      fill: #ffffff;
    }
  }

  &.btn-outline-primary, &.btn-outline-danger, &.btn-outline-warning, &.btn-outline-success, &.btn-outline-green, &.btn-outline-info {

    &:hover, &:focus, &:active, &:active:focus {
      color: #ffffff;
      background: transparent;
    }
  }

  &.btn-primary, &.btn-danger, &.btn-warning, &.btn-green, &.btn-outline-primary, &.btn-outline-secondary, &.btn-outline-info, &.btn-outline-danger, &.btn-outline-warning, &.btn-outline-success, &.btn-outline-green {

    &:hover, &:focus, &:active, &:active:focus {

      p {
        color: #ffffff;
      }

      svg {
        fill: #ffffff;
      }
    }

  }

  &.expand {

    svg {
      width: 0;
      transition: all 0.3s;
    }

    &.expand--load {

      @keyframes rotating {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
      svg {
        width: 14px;
        animation: rotating 2s linear infinite;
      }
    }
  }
}

.btn-add-new{
  svg {
    height: 24px;
    width: 24px;
  }
}

.anchor_button{
  padding: 0;
  margin-bottom: 5px;
  &:hover, &:focus, &:active, &:active:focus {
    outline: none;
    box-shadow: none !important;
    color: $color-accent !important;
    &:before {
      background: $color-accent;
      height: 0%;
      width: 0%;
    }
  }
}

.delete_button{
  padding: 0 !important;
  margin-bottom: 5px !important;
  border:none !important;
  color: $color-accent2 !important;
  font-size: 12px !important;
  svg {
    fill: $color-accent2 !important;
    width: 16px !important;
    height: 16px !important;
  }
  &:hover, &:focus, &:active, &:active:focus {
    outline: none !important;
    box-shadow: none !important;
    &:before {
      background: $color-accent2 !important;
      color: $color-accent2 !important;
      height: 0% !important;
      width: 0% !important;
    }
  }

  &.btn-outline-primary {
    &:hover, &:focus, &:active, &:active:focus {
      p {
        color: $color-accent2 !important;
      }
      svg {
        fill: $color-accent2 !important;
      }
    }
  }
}
.btn-tooltip {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}

.btn-toolbar {
  margin-top: 15px;
  margin-bottom: 10px;

  & > * {
    margin-right: 15px;

    &:last-child and :not(:only-child) {
      margin-right: 0;
      @media screen and (max-width:330px){
        margin-left: 15px;
      }
    }
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: -10px;
    @media screen and (max-width:330px){
      justify-content: center;
    }
  }

  &.btn-toolbar--icons {

    .btn {
      padding: 7px 8px;
      line-height: 14px;
      margin: 2px 2px 0px 0px;
    }
  }

  &.btn-toolbar--center {

    & > * {
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.btn-group {
  border-radius: 5px;
  margin-bottom: -10px;

  .btn {
    margin-right: 0;
    padding: 10px 15px;
    font-weight: 500;
  }

  &.btn-group--justified {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .btn {
      width: 100%;
    }
  }

  &.btn-group--icons {

    .btn {
      padding: 7px 8px;
      line-height: 14px;
    }
  }

  &.open .dropdown-toggle {
    box-shadow: none;
  }
}

button:focus, button:active {
  outline: none;
}

.arrow__icon-size {
  width: 24px !important;
  height: 24px !important;
}

.open > .dropdown-toggle.btn-default,
.btn-default:active:focus, .btn-default:active:focus,
.btn-default.focus, .btn-default:focus {

  @include themify($themes) {
    background-color: themed('colorBackground');
  }
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle)
{
  @media screen and (max-width: 768px){
    border-radius: 5px;
  }
}
.btn-group > .btn:not(:first-child)
{
  @media screen and (max-width: 768px){
    border-radius: 5px;
    margin-left: 0px;
  }
}

.login-button-seperator {
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  margin: 5px 0px;
  font-size: 12px;
  text-align: center;
  color: #252322;
  @media screen and (max-width: 1500px){
    font-size: 11px;
  }
}

.full-width-button-left {
  &:not(:only-child){
    width: 48% !important;
    margin-right: 4% !important;
  }
  &.login-button {
    font-size: 15px;
    padding: 9px 25px;

    @media screen and (max-width: 1500px) {
      font-size: 13px;
    }
  }
}
.full-width-button-right {
  width: 48%;
  white-space: nowrap;

  &.login-button {
    font-size: 15px;

    @media screen and (max-width: 1500px) {
      font-size: 13px;
    }
  }
}

.btn-link-no-background {
  padding: 0px;
  margin-bottom: 0px;
  &::before {
    background-color: transparent!important;
  }
}

*[class^="MuiTableCell-root"] { // doesn't work on build (MuiTableCell-root is changed to jss-*number* on build)
  .btn-table-icons {
    padding: 6px 7px !important;
    font-size: 12px !important;
  }
}

.btn-table-icons {
  padding: 6px 7px !important;
  font-size: 12px !important;
}
