.form__form-group-input-wrap {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  
  @media screen and (max-width: 1500px) {
    font-size: 12px;
  }
}

.react-select__input input{
  height: 18px;
  margin: 0px;
}

.react-select__placeholder {
  font-family: 'Source Sans Pro', sans-serif;
  color: $color-input-placeholder;
  font-size: 14px;

  @media screen and (max-width: 1500px) {
    font-size: 12px;
  }
}
