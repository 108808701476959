.tooltiplist, .tooltiplist-backdrop{
  z-index: 1202;
  .prev_button{
    margin-right: 0.5rem;
    @media screen and (max-width: 767px){
      // border-right: none;
    }
    @media screen and (max-width: 992px) and (min-width: 768px){
      // border-right: 1px solid;
    }
  }

  .btn-tooltiplist {
    padding: 3px 15px;
    border: 1px solid;
    padding: 3px 10px;
  }

  &:focus{

  }

  .list-popper {

  }
  .list-inner-backdrop, .list-inner {
    background-color: #8c8af5;
    max-width: 350px;
  }

.tooltiplist_context {
  padding: 10px;
}

  .list-arrow-backdrop, .list-arrow {
    &:before {
      border-bottom-color: #8c8af5;
    }
  }
}
.tooltiplist-item {
  &:focus{
    // border: solid #888888 0.25px;
    // border-radius: 5px;
    // box-shadow: 5px 10px 10px #888888;
  }
  &.selected-item {
    // border: solid #888888 0.25px;
    border-radius: 5px;
    box-shadow: 5px 10px 25px #888888;
    padding: 7px;
    z-index: 1202;
    position: relative;
    background-color: #fff;
  }
}

.tooltiplist-backdrop, .tooltiplist {
  opacity: 1 !important;
}
.tooltiplist-backdrop {
  z-index: 150 !important;
  color: #fff;
}
