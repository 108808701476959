.go-back-button-toolbar{
  margin: 0;
  margin-top: 10px;

  .back_button {
    display: contents;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 20px;
    font-weight: 600;

    &:hover, &:focus, &:active, &:active:focus {
      opacity: 0.1;
      &:before {
        height: 0%;
        width: 0%;
      }
    }

    b {
      margin-left: -6px;
      line-height: 52px;
      color: $color-content;
    }
  }
  
  .arrow__icon {
    width: 50px;
    height: 55px;
    margin: 0;
    fill:#A4AFB7;
    margin-left: -13px;
  }
}