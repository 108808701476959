.editable-table.table_icons {
  font-size: 15px;
}

.MuiTable-root tr td:first-child div {
  justify-content: center;
}

.table-toolbar-tabs {
  margin-bottom: -22px;
  .toolbar-btn {
    background-color: white;
    border-color: white;
    &::before {
      background-color: #f7f7fb;
    }
    &:active {
      background-color: #f7f7fb;
    }
  }
}

.table-toolbar-btn {
  &.active, &::before {
    font-size: 17px;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out, font-size 0.3s ease-in-out;
  }
}
.MuiIconButton-root {
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  padding: 12px !important;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50% !important;
}
.MuiButtonBase-root {
  color: inherit;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}
.MuiIconButton-label {
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: inherit;
}

.MuiTouchRipple-root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  overflow: hidden;
  position: absolute;
  border-radius: inherit;
  pointer-events: none;
}