.load {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  position: fixed;
  background: #ffffff;
  z-index: 1000;

  & + div {
    height: 100vh;
    overflow: hidden;
  }

  &.loaded {
    animation: ease loaded 0.5s;
  }
}

.load__icon {
  animation: linear load 2s infinite;
  margin: auto;
  width: 32px;
  height: 32px;
}

.load__icon-wrap {
  margin: auto;
}

@keyframes load {
  from {
    transform: rotate(0deg) scale(2);
  }
  to {
    transform: rotate(360deg) scale(2);
  }
}

@keyframes loaded {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.loading_wrapper{
  display: grid;
  .loading_logo_wrapper, .loading_animation_wrapper{
    display: grid;
    grid-row: 1;
    grid-column: 1;
  }
  .loading_animation_wrapper{
    width: 100%;
    height: 100%;
    .loading_spinner{
      width: 5% !important;
      height: auto !important;
      min-width: 4rem !important;
      min-height: auto !important;
      z-index: 30;
      margin: auto;
    }
  }
  .loading_logo_wrapper{
    .loading_logo{
      width: 3.5rem;
      height: 100%;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 50%;
      margin: auto;
    }
  }
}
.loading_placeholder{
  background-color: #F0F0F7;
  border-radius: 10px;
}

