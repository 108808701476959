.react-scrollable-list {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  max-height: 300px;
  width: 100%;
  overflow-y: scroll;
  border-color: hsl(0,0%,80%);
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
}

.react-scrollable-list-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 40px;
  padding: 10px;
  background-color: transparent;
  border-bottom: #f7f7f7 solid 1px;
  pointer-events: none;
}