/* ShareSocialMedia.css */
.share_buttons {
  margin-top: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.share_buttons button {
  border-radius: 50%;
  margin: 5px;
}
