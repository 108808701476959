.survey-report-form-group{
  .panel__body {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .table-responsive{
    height: 200px !important;
  }
}

// Table Filter Icon Style
.table-filter{
  font-size: 17px !important;
}

.university-request-table-wrapper {
  .MuiTableRow-root {
    width: 100% !important;
    display: flex;
  }
}
.mobile-preview-inner {
  width: 100%;
  padding-right: 2px;
  max-height: 700px;
  margin-bottom: -25px;
  overflow: auto;
  margin-top: 30px;
  margin-right: -17px;
  padding-left: 3px;
  padding-bottom: 50px;
}
.mobile-preview-outer {
  width: calc(100% - 50px);
  max-width: 375px;
  margin-top: 24px;
  border-radius: 35px;
  margin-bottom: 37px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.mobile-preview-root {
  max-width: 430px;
  height: 755px;
  border-radius: 66px !important;
  background-repeat: round;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width : 600px) {
    zoom: 0.8;
  }
}
.mobile-card-preview-inner {
  width: 98%;
  margin-right: auto;
  margin-left: auto;
  max-height: 700px;
  margin-bottom: -25px;
  overflow: auto;
  margin-top: 30px;
  padding-bottom: 50px;
 
}
.mobile-card-preview-outer {
  width: calc(100% - 50px);
  max-width: 375px;
  margin-top: 24px;
  border-radius: 35px;
  margin-bottom: 37px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.mobile-card-preview-root {
  max-width: 430px;
  height: 755px;
  border-radius: 66px !important;
  background-repeat: round;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width : 600px) {
    zoom: 0.8;
  }
}
.desktop-preview-inner {
  width: 100%;
  padding-right: 0px;
  height: 592px;
  overflow: auto;
  margin-right: -17px;
  padding-left: 1px;
}
.desktop-preview-outer {
  width: 1170px;
  height: 750px;
  margin-top: 45px;
  margin-bottom: 37px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.desktop-preview-root {
  width: 1280px;
  height: 855px;
  margin-left: auto;
  margin-right: auto;
  background-repeat: round;

  @media screen and (max-width: 1500px) {
    zoom: 0.9;
  }
   @media screen and (max-width: 1350px) {
    zoom: 0.8;
  }
  @media screen and (max-width: 1200px) {
    zoom: 0.7;
  }
  @media screen and (max-width: 1050px) {
    zoom: 0.6;
  }
  @media screen and (max-width: 900px) {
    zoom: 0.5;
  }
  @media screen and (max-width: 750px) {
    zoom: 0.4;
  }
  @media screen and (max-width : 600px) {
    zoom: 0.3;
  }
  @media screen and (max-width : 470px) {
    zoom: 0.2;
  }
}
.instagramGradientColor {
  background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.disabled_input {
  border: 1px solid #E9E9F0;
  border-radius: 4px;
  background-color: #f2f2f2;
  width: 100%; 
  padding: 4px;
  padding-left: 10px;
}
.table_field_input {
  border-bottom: 1px solid !important;
  border-bottom-color: black !important;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  &::after {
    transform: scaleX(0) !important;
  }
  &:hover {
    border-bottom: 2px solid !important;
    border-bottom-color: black !important;
  }
  &:active, &:focus {
    border-bottom: 2px solid !important;
    border-bottom-color: blue !important;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    // transform: scaleX(0) !important;
  }
  &::after &:focus {
    transform: scaleX(1) !important;
  }
}

.rounded-xs {
  border-top-right-radius: 0.25rem!important;
  border-bottom-right-radius: 0.25rem!important;
  border-top-left-radius: 0.25rem!important;
  border-bottom-left-radius: 0.25rem!important;
}

.rounded-right-md {
  @media (min-width: 768px) {
    border-top-right-radius: 0.25rem!important;
    border-bottom-right-radius: 0.25rem!important;
    border-top-left-radius: 0px!important;
    border-bottom-left-radius: 0px!important;
  }
}
.w-md-100 {
  @media (min-width: 768px) {
    width: 100%!important;
  }
}

.MuiTypography-root{
  width: 100%;
  margin: 0 !important;
}
.MuiAccordionSummary-root.Mui-disabled {
  opacity: 1 !important;
}
.MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0 !important;
}
.MuiTablePagination-caption {
  width: auto;
}
.danger_info_container{
  background-color: #f6a7a3 !important;
  color: #140304;
  border-left: 5px solid #8f120d;
  font-weight: 600;
}

.fair-detail .fair-desc {
  & span, &p {
    color: #212529;
  }
}

// vertical rule (seperator)

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 1;
}

.custom_year_picker .react-datepicker__year .react-datepicker__year-wrapper {
  justify-content: center;
}

.custom_year_picker .react-datepicker__header.react-datepicker-year-header {
  color:#ffffff
}

.smartbanner{
  z-index: 99999;
}

.share_popover {
  z-index: 1040!important;
}