.card {
  font-family: 'Source Sans Pro', sans-serif;
  width: 100%;
  padding-bottom: 30px;
  height: 100%;
  border: none;
  background-color: transparent;

  &.card--not-full-height {
    height: auto;
  }

  .max_width{
    width: 100%;
    .loading_wrapper{
      width: 100%;
      height: 100%;
    }
  }

  .loading_wrapper{
    display: grid;
    .loading_logo_wrapper, .loading_animation_wrapper{
      display: grid;
      grid-row: 1;
      grid-column: 1;
    }
    .loading_animation_wrapper{
      width: 100%;
      height: 100%;
      .loading_spinner{
        width: 5% !important;
        height: auto !important;
        min-width: 4rem !important;
        min-height: auto !important;
        z-index: 30;
        margin: auto;
      }
    }
    .loading_logo_wrapper{
      .loading_logo{
        width: 3.5rem;
        height: 100%;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
        margin: auto;
      }
    }
  }
  .loading_placeholder{
    background-color: #F0F0F7;
    border-radius: 10px;
  }
}

.card-body {
  padding: 30px;
  height: 100%;
  border-radius: 5px;

  @include themify($themes) {
    background-color: themed('colorBackground');
  }
    .card-body-mobile{
      width: 315px;
      height: 500px;
      float: right;
      @media screen and (max-width:1023px){
        width: auto;
        height: auto;
        float: none;
      }
    }
    .profileImageCard{
      justify-content: flex-end; //pushes profile picture to right side
      padding-right: 0px;
    }
    // .unshare_stacked{
    //   &:before{
    //     content: "\f064";
    //     // position: absolute;
    //     // width: 100%;
    //     z-index: 10;
    //     grid-column: 1;
    //     grid-row: 1;
    //     font-size: 16px;
    //   }
    //   &:after{
    //     content: "\f715";
    //     // position: absolute;
    //     width: 100%;
    //     grid-column: 1;
    //     grid-row: 1;
    //     font-size: 16px;
    //   }
    // }
}

.card__title {
  margin-bottom: 30px;
  text-transform: uppercase;
  position: relative;

  /*
  &:not(:first-child) {
    margin-top: 40px;
  } */

  .subhead {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    margin-top: 3px;
  }

  * {
    margin-bottom: 0;
  }

  h5 {
    font-size: 16px;

    @media screen and (max-width: 1500px) {
      font-size: 14px;
    }
  }
}

.squared-corner-theme {

  .card-body {
    border-radius: 0;
  }
}

.blocks-with-shadow-theme {

  .card-body {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  }
}
.scrollable {
  display: flex;
  flex-direction: column;
  line-height: 1rem;
}

.purpleHoverBorder {
  &:hover{
    border-color:#9c27b0;
  }
}
.blueHoverBorder {
  &:hover{
    border-color:#0a6dab;
  }
}
.greenHoverBorder {
  &:hover{
    border-color:#37b027;
  }
}

.activity-card {
  margin-bottom: 30px;
  border-width: 1px 1px 4px 1px;
  border-style: solid;
  border-color: #fff;
  background-color: #fff;
  transition: all 0.35s ease-in-out 0s;
  box-shadow: 1px 1px 9px 0px #00000921;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  line-height: 1.2;
  font-size: 16px;
 
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    max-width: 800px;
  }
} 
.d-400-block {
  @media screen and (min-width: 400px) {
    display: block !important;
  }
}
.d-400-none {
  @media screen and (min-width: 400px) {
    display: none !important;
  }
}
.deleted {
  display: block;
  font-size: 20px;
  position: absolute;
  top: 40%;
  text-align: center;
  width: 100%;
}

.owner-logo {
  overflow: hidden;
  border: 1px solid #dddddd;
  width: 140px;
  padding: 10px;
  border-radius: 4px;
  align-items: center;
  display: flex;
  justify-content: center;
  max-height: 120px;
}

.owner-info {
  width: 100%;
  padding-left: 20px;
  display: flex;
  color:'#6a6a6a';
  
}
.right-icon{
  color:blue;
  @media screen and (max-width: 768px) {
    font-size: 25px;
    margin-right: 35px;
    margin-bottom: 25px;
  }
}
.urgent-red {
  z-index: 99;
  text-transform: uppercase;
    padding: 0 25px;
    display: inline-block;
    position: absolute;
    top: 20px;
    right: -30px;
    -webkit-transform: rotate(
45deg
);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(
45deg
);
    background: #d9534f;
    color: #fff;
    font-size: 13px;
    @media screen and (max-width: 768px) {
      font-size: 11px;
      padding: 0 15px;
      top: 13px;
      right: -20px;
    }
}
.urgent-green {
  z-index: 99;
  text-transform: uppercase;
    padding: 0 25px;
    display: inline-block;
    position: absolute;
    top: 25px;
    right: -30px;
    -webkit-transform: rotate(
45deg
);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(
45deg
);
    background: #2b8d2b;
    color: #fff;
    font-size: 13px;
    @media screen and (max-width: 768px) {
      font-size: 11px;
      padding: 0 15px;
      top: 20px;
      right: -20px;
    }
}
.urgent-blue {
  z-index: 99;
  text-transform: uppercase;
    padding: 0 25px;
    display: inline-block;
    position: absolute;
    top: 25px;
    right: -30px;
    -webkit-transform: rotate(
45deg
);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(
45deg
);
    background: #0275d8;
    color: #fff;
    font-size: 13px;
    @media screen and (max-width: 768px) {
      font-size: 11px;
      padding: 0 15px;
      top: 20px;
      right: -21px;
    }
}
.urgent-purple {
  z-index: 99;
  text-transform: uppercase;
    padding: 0 25px;
    display: inline-block;
    position: absolute;
    top: 25px;
    right: -30px;
    -webkit-transform: rotate(
45deg
);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(
45deg
);
    background: purple;
    color: #fff;
    font-size: 13px;
    @media screen and (max-width: 768px) {
      font-size: 11px;
      padding: 0 15px;
      top: 20px;
      right: -21px;
    }
}
.fitVideo{
  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    max-height: 500px;
  }
}
.border-left-lg-1-grey{
  @media screen and (min-width: 991px) {
    border-left: 1px solid #00000021;
  }
}
.appImg {
  padding-bottom: 0.5rem;
}
img.ownerProfile {
  width: 100%;
  height: auto;
}
.sponsor_img{
  max-width: 140px;
  max-height: 140px;
  // border-radius: 50%;
  opacity: 0.7;
  @media screen and (max-width: 600px) {
    max-width: 100px;
    max-height: 100px;
  }
  &:hover{
    opacity: 1;
  }
}
.sponsor_img_div {
  width: 140px;
  height: 140px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 600px) {
    width: 100px;
    height: 100px;
  }
}

.sponsor_img_card{
  width: 60px;
  height: 60px;
  // border-radius: 50%;
  opacity: 0.7;
  @media screen and (max-width: 600px) {
    width: 55px;
    height: 55px;
  }
  @media screen and (max-width: 370px) {
    width: 45px;
    height: 45px;
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    width: 47px;
    height:47px;
  }
  &:hover{
    opacity: 1;
  }
}

.centerize_div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  & > img {
    margin-left: auto;
    margin-right: auto;
    width: auto;
  }
  //width ve height dışardan verilmeli, aynı verilen değer içerdeki img e ise max-width, max-height olarak verilmeli
}