/* 
--------------------------------------------------------------------------------------
 (1) '/company_register' URL'indeki 'İşveren Kaydı' formunun tasarımının düzeltilmesi
--------------------------------------------------------------------------------------
*/

form#companyRegistrationForm div.form__form-group-field {
    padding-left: 0px !important;
    margin-left: 0px !important;
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;
}

form#companyRegistrationForm div.form__form-group-field:has(h5) { 
    max-width: 100% !important;
    width: 100% !important;
}

form#companyRegistrationForm div.student_register_form_group {
    flex-direction: row !important;
}

form#companyRegistrationForm label.checkbox-btn {
    display: inline-flex !important;
}

form#companyRegistrationForm button.form__form-group-label {
    line-height: 18px !important;
    font-size: 13px !important;
}

form#companyRegistrationForm div#terms-splitter {
    border-top: 1px solid gray !important;
    padding-bottom: 20px !important;
}

form#companyRegistrationForm span.form__form-group-label {
    width: 100% !important;
}

form#companyRegistrationForm span.radio-btn__label {
    white-space: normal !important;
    word-wrap: break-word !important;
    text-align: left !important;
}

/* Aşağıdaki %70 ve %30'lar, sayfadaki mobil uyumluluğu ve tutarlı görünümü sağlamak amacıyla son çare olarak eklenmiştir. */
form#companyRegistrationForm span.form__form-group-label.term-field-desc-label {
    width: 70% !important;
    margin-right: 30% !important;
    margin-bottom: 10px !important;
}

form#companyRegistrationForm span#terms-checkbox-span {
    margin-top: 7px !important;
    text-align: left !important;
    width: calc(70%) !important;
    white-space: normal !important;
    word-wrap: break-word !important;
}

/* 
------------------------------------------------------------------------------------
  (2) ... 
------------------------------------------------------------------------------------
*/
