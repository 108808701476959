.custom-shape-divider-top-1661853625 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  /* transform: rotate(180deg); */
  z-index: 1800;
}

.custom-shape-divider-top-1661853625 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 154px;
  transform: rotate(180deg);

}

.custom-shape-divider-top-1661853625 .shape-fill {
  --fillColor: #0A6DAB;
  fill: var(--fillColor);
}

.custom-shape-divider-top-1661853625 svg foreignObject.logo-container {
  width: 100%;
  /* height: 100%; */
  height: 80px;
  transform: none!important;
  transform: rotate none!important;
}

.logo_container .logo {
  width: 320px;
}

@media screen and (max-width: 768px) {
  .logo_container .logo{
    width: 280px;
  }
}

@media screen and (max-width: 576px) {
  .logo_container .logo{
    width: 200px;
  }
}

/* .custom-shape-divider-top-1661853625 .logo_container {
  position: absolute;
  display: block;
  transform: none!important;
  transform: rotate none!important;
} */

.logo_container {
  position: relative;
  z-index: 1900;
}