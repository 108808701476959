.timeline {
  position: relative;

  @include themify($themes) {
    color: themed('colorText');
  }

  &:before {
    content: "";
    background-color: #dddddd;
    width: 1px;
    height: 100%;
    position: absolute;
  }

  p {
    margin-top: 5px;
  }
}

.timeline__item {
  position: relative;
  padding-bottom: 50px;

  &:nth-child(even) {

    .timeline__content {
      

      &:after {
        
        border: 10px solid transparent;

      }
    }
  }
}

.timeline__icon {
  position: absolute;

  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: $color-accent;
  overflow: hidden;
  text-align: center;
  display: flex;

  &.work {
    background-image: linear-gradient(to bottom, #4ce1b6, #1cd25a), linear-gradient(#4ce1b6, #4ce1b6);
  }

  &.video {
    background-image: linear-gradient(to bottom, #708eee, #9e44f3), linear-gradient(#7082ee, #7082ee);
  }

  &.file {
    background-image: linear-gradient(to bottom, #ee4f99, #f76e5b);
  }

  img {
    height: 100%;
    width: auto;
    min-width: 100%;
  }

  span {
    color: white;
    font-size: 20px;
    margin: auto;
  }
}

.timeline__content {
  width: calc(50% - 52px);

  padding-top: 25px;
  padding-bottom: 25px;
  border-radius: 5px;
  position: relative;

  @include themify($themes) {
    background-color: themed('colorBackground');
  }

  &:after {
    content: '';
    position: absolute;
    top: 20px;
    border: 10px solid transparent;

    }
  
}

.timeline__title {
  text-transform: uppercase;
  font-weight: 700;
}

@media screen and (max-width: 991px) {

  .timeline:before {
  }

  .timeline__icon {
  
  }

  .timeline__item:nth-child(even) .timeline__content,
  .timeline__item .timeline__content {
    width: calc(100% - 72px);

    &:after {
      border: 10px solid transparent;

    }
  }
}