.rc-time-picker {
  width: 100% !important;
}

.rc-time-picker-input {
  border-radius: 0 !important;
}

.rc-time-picker-panel {
  padding-top: 3px !important;
}

.rc-time-picker-panel-input-wrap {
  display: none !important;
}

.rc-time-picker-panel-inner {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05) !important;
  border: none !important;
  border-radius: 0 !important;
  margin-top: 32px !important;
}

.rc-time-picker-panel-select {
  overflow: hidden !important;
  border-right: 1px solid #eff1f5 !important;

  ul {
    padding-right: 30px !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    width: 86px !important;
    height: 144px !important;
  }

  li {
    padding: 0 !important;
    text-align: center !important;
    transition: 0.3s !important;
    width: 56px !important;
  }
}

li.rc-time-picker-panel-select-option-selected {
  background: $color-blue !important;
  color: white !important;

  &:hover {
    background: $color-blue-hover !important;
  }
}