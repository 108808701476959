.pws-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 0px;
}

.bar-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: auto;
  position: relative;
}

.bar {
  height: 15px;
  border: 1px solid gray;
}

.bar:not(:last-child) {
  margin-right: 1.5px;
}

.first {
  background: #60CBE1;
}

.second {
  background: #5AB9D5;
}

.third {
  background: #38B2C9;
}

.fourth {
  background: #2A8D9F;
}

.fifth {
  background: #1C698D;
}

.pointer-container {
  position: absolute;
  top: 31px;
  left: 0px;
  width: 50px;
  height: 50px;
}

.pointer {
  width: 30px;
  height: 30px;
  margin-top: 3px;
  margin-left: 2px !important;
  background-color: white;
  border-radius: 50%;
}

.pointer-text {
  position: absolute;
  top: 5px;
  left: 10px;
  text-align: center;
  
  font-weight: bold;
  color: #28a745;
  font-size: 17px;
  background-color: transparent;
}

.picker {
  height: 50px;
  width: 150px;
  margin-top: 20px;
}