.container {
  padding-right: 20px;
  padding-left: 25px;

  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 2100px;
  }
  @media screen and (max-width: 768px) {
    max-width: 100% !important; 
    padding-left: 20px;
  }
}

.container__wrap {
  padding-left: 0;
  padding-top: 110px;
  min-height: 100vh;
  transition: padding-left 0.3s;

  @include themify($themes) {
    background: themed('colorBackgroundBody');
  }

  @media screen and (min-width: 1500px) {
    padding-left: 240px;
  }

  @media screen and (min-width: 769px) and (max-width: 1500px) {
    padding-left: 220px;
  }
}

.layout {

  &.layout--collapse {
    .topbar__left-collapse-button {
      width: auto;
    }

    & + .container__wrap {
      padding-left: 0;

      @media screen and (min-width: 76px) {
        padding-left: 60px;
      }
    }
  }

  &.layout--top-navigation{

    & + .container__wrap{

      @media screen and (min-width: 768px) {
        padding-left: 0;
      }
    }
  }
}

