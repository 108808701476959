.form {
  font-family: 'Source Sans Pro', sans-serif;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  min-width: 90%;
  .rdw-link-decorator-icon {
    width: auto;
    left: 100%
  }
  .daypicker-table {
    th {
      text-align: center;
    }
    .checkbox > label {
      input {
        width: 2rem;
      }
    }
  }
  .checkbox > label {
    display: flex;
    justify-content: center;
    padding-left: 20px;
    span {
      margin-top: 0.4rem;
    }
    input {
      width: min-content;
    }
  }
  .checkbox-md .checkbox input {
    width: 1.5rem;
  }
  #editModalButton {
    display: none;
  }

  input, textarea {
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    height: 32px;
    transition: border 0.3s;
    background: transparent;
    border-radius: 4px;


    &::-webkit-input-placeholder {
      color: $color-additional;
    }
    &::-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: $color-additional;
    }

    @include themify($themes) {
      border: 1px solid themed('colorFieldsBorder');
      color: themed('colorText');
    }

    &[disabled] {

      @include themify($themes) {
        background: #f2f2f2
      }

      &:focus, &:active {

        @include themify($themes) {
          border-color: themed('colorBorder');
        }
      }
    }

    &:focus, &:active {
      outline: none;
      border-color: $color-accent;
    }
  }

  textarea {
    min-height: 85px;
  }

  &.form--horizontal {
    .form__form-group {
      display: flex;
      flex-wrap: wrap;
      //flex-direction: column;
      //width: 520px;
      @media screen and(max-width:767px){
        flex-direction:column;
      }
      &.form-question {
        flex-direction: column;
        margin-bottom: 15px;
        &> span {
          width: 100%;
          margin-bottom: 3vh;
          font-weight: 600;
          font-size: 1.1rem;
        }
      }
    }

    .form--horizontal2 {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      //flex-direction: column;
      @media screen and(max-width:767px){
        flex-direction: column;
      }
      .form__form-group{
        width: 520px;
      }
    }

    .form__form-group--title {
      .form__form-group-field {
        font-size: 20px;
      }
    }


    .form__form-group-field {

      width: calc(100% - 80px);
      padding-left: 15px;
      margin-left: 80px;
      color: #707070;
      @media screen and(max-width:767px){
        width: 100% !important;
        margin-left: 0px !important;
        flex-direction: row;
        padding-left: 0 !important;
        .date-picker.date-picker--interval{
          flex-direction: column;
        }
      }
	  &.padding{
		padding: 15px!important;
	  }
      &.desired_majors_mobile
      {
        @media screen and (max-width:767px){
          flex-direction: column !important;
        }
      }
      h1, h2, h3, h4, h5, p {
        color: #707070;
      }
      &.password-rules {
        color: grey;
        display: flex;
        margin-bottom: 0px;
        flex-direction: column !important;
        .text-invalid {
          color: red;
        }
        .text-valid {
          color: green;
        }
      }
    }
    .form__form-group > .form__form-group-field > div > div > ul:last-child > a
    {
      @media screen and (max-width:767px){
        display: inline-block;
        max-width: 70vw;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: top
      }
    }
    .form__form-group-field-mobile
    {
      @media screen and (max-width: 767px){
        flex-direction: column !important;
      }
    }
    .form__form-group-field-table {
      width: 100%;
      padding-left: 40px;
      margin-left: 100px;
      margin-bottom: 40px;
      @media screen and (max-width:767px){
        padding-left: 0px;
        margin-left: 0px;
      }
      @media screen and (min-width:768px) and (max-width:1024px){
        width: calc(100% - 120px);
        padding-left: 20px;
        margin-left: 120px;
      }
    }
    @media screen and (max-width:1024px) {
      .material-table__wrap > div > table {
        display: block;
        width: 100%;
        overflow-x: hidden;
        tfoot tr td [style="flex-grow: 1; flex-shrink: 1; flex-basis: inherit; text-align: center; align-self: center;"]{
          display: none;
        }
      }
    }
    @media screen and (max-width:767px) and (min-width:330px){
      .form__button-toolbar.btn-toolbar.wizard__toolbar{
        width: 100%;
        justify-content: center;
        button:only-child{
          width: 100%;
        }
      }
    }

    @media screen  and (max-width:330px) {
      .form__button-toolbar.btn-toolbar{
        button{
          width: 100%;
          margin-left: 5%;
        }
        &.wizard__toolbar{
          width: 100%;
        }
      }
      .material-table__wrap > div > table [aria-haspopup="true"] > div{
        display: none;
      }
    }
    .form__form-group-label {
      width: 80px;
      max-height: 32px;
      line-height: 18px;
      margin: auto 0;
      //word-wrap: normal;
      @media screen and(max-width:767px){
        width: 100%;
      }
      @media screen and(max-width:568px){
        max-height: 37px;
      }


      & + .form__form-group-field {
        margin-left: 0;
        flex-direction: row;
      }
    }

    .form__form-group-description {
      font-size: 14px;
      margin-left: 90px;
      margin-bottom: 5px;
      padding-bottom: 3px;
      color: #bcbdbe;
      @media screen and (max-width: 1500px) {
        font-size: 12px !important;
      }
      @media screen and(max-width:767px){
       margin-left: 0px !important;
      }
      &.form-midSizeText {
        font-size: 1rem;
        line-height: 3vh;
        margin-left: 0px !important;
        color: #6c757d;
      }
    }
    .question-description > .form__form-group > .form__form-group-description {
      margin-bottom: 10px;
    }

    .form__form-group-character {
      margin-left: auto;
      color: #CECFD0;
    }

    .form__button-toolbar {
      margin-left: 0;
      @media screen and (max-width:992px){
        margin-left: 0 !important;
      }
      @media screen and (min-width:480px){
        align-self: flex-start;
      }
      @media screen and(max-width:576px){
        align-self: center;
      }
      &.toolbar-detailed
      {
        @media screen and (max-width:1024px){
          position: absolute;
          margin-top: -50px !important;
        }
      }
    }

    .form__form-group-input-wrap--error-above {
      margin-bottom: 15px;

      .form__form-group-error {
        top: -28px;
      }
    }

    @media screen and (min-width: 480px) {

      .form__form-group-label {
        width: 120px;
        flex-direction: column;
        //width: 520px;
      }

      .form__form-group-field {
        width: calc(100% - 120px);
        margin-left: 120px;
        padding-left: 20px;
      }

      .form__button-toolbar
      {
        margin-left: 140px;
      }
    }
    .warning-card {
      right: 0;
      width: 50%;
      align-self: flex-end;
      border: 1px dashed red;
      border-radius: 10px;
      .lnr {
        color:red;
        font-weight: 600;
        font-size: 1.2rem;
      }
    }
    .warning-box {
      display: flex;
      flex-direction: column;
      .warning-sign {
        display: inline;
      }
      .warning-text {
        font-size: 0.9rem;
        margin-left: 0.4rem;
      }
      .warning-wrapper {
        margin: 3px 1px 3px 5px;
      }
    }
  }

  &.form--preview {
    display: flex;

    & > div:nth-child(2) {
      margin-right: 50px;
    }

    .form__form-group {
      margin-bottom: 10px;
      width: auto;
      min-height: 18px;
    }

    .form__select-color {
      display: flex;
      flex-wrap: wrap;
      max-width: 84px;
      margin-right: 40px;
    }

    .p {
      margin-bottom: 10px;
    }
  }

  &.form--justify {
    display: flex;
    flex-wrap: wrap;

    .form__form-group {
      width: 33.3333%;
    }

    .form__button-toolbar {
      width: 100%;
    }
  }

  .form__form-group-select {
    width: 100%;
    height: 32px;
    font-size: 12px;

    .Select-control {
      height: 32px;
      border-radius: 0;
      transition: all 0.3s;
      background: transparent;

      @include themify($themes) {
        border: 1px solid themed('colorFieldsBorder');
      }
    }

    .Select-placeholder, .Select-input {
      height: 30px;
    }

    .Select-input {
      width: 100%;
    }

    input {
      width: 100% !important;
      border: none;
      padding: 0;
    }

    .Select-multi-value-wrapper {

      .Select-input {
        width: inherit;
      }

      .Select-value {
        background: transparent;
        border-color: $color-blue;
      }

      .Select-value-label {

        @include themify($themes) {
          color: themed('colorText') !important;
        }
      }

      .Select-value-icon {
        border-color: $color-blue;
      }
    }

    .Select-menu-outer {
      top: calc(100% + 1px);
      border-radius: 0;
      box-shadow: none;
      font-size: 12px;
      animation: open 0.3s ease-in-out;
      overflow: hidden;

      @include themify($themes) {
        border: 1px solid themed('colorBorder');
        background: themed('colorBackground');
      }
    }

    @keyframes open {
      0% {
        max-height: 0
      }
      100% {
        max-height: 200px
      }
    }

    .Select-option {
      transition: all 0.3s;
      border-radius: 0;
      display: flex;

      @include themify($themes) {
        background: themed('colorBackground');
        color: themed('colorText');
      }

      &.is-focused {
        @include themify($themes) {
          background: themed('colorHover');
        }
      }
    }

    &.is-focused, &.is-focused:not(.is-open) {

      .Select-control {
        border-color: $color-accent;
        box-shadow: none;
        background: transparent;
      }
    }

    .form__form-group-select-color {
      display: block;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      margin: auto 0 auto 5px;
    }
  }
  .MTablePaginationInner-root-158{
    @media screen and (max-width:330){
      flex-wrap: wrap;
    }
  }

  .player-wrapper {
    width: 50%;
    min-height: 300px;
  }

  .validation-code-input {
    margin-right: 10px;
    height: 50px;
    text-align: center;
    font-weight: 500;
    font-size: large;
    border-radius: 10px;
    border-color: rgb(33, 37, 41);
  }
}

.form__half {
  width: calc(50% - 15px);
  height: 100%;

  &:first-child {
    margin-right: 30px;
  }

  .form__button-toolbar {
    float: right;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-right: 0;
  }
}

.form__form-group {
  margin-bottom: 30px;
  width: 100%;
  position: relative;
}

.form__form-group--address {

  input:last-child {
    margin-left: 15px;
    width: 70%;
  }
}

.form__form-group-field {
  font-size: 14px;
  width: 100%;
  display: flex;
  margin: auto;
  @media screen and (max-width: 1500px){
    font-size: 12px;
  }
  &.mobile{
    @media screen and (max-width: 767px){
      flex-direction: row !important;
    }
  }
}

.form__form-group-label {
  font-size: 14px;
  margin-bottom: 4px;
  display: inline-block;

  @media screen and (max-width: 1500px){
    font-size: 12px;
  }

  @include themify($themes) {
    color: themed('colorText');
  }

  span {
    color: #dddddd;
  }
}

.form__form-group-button {
  padding: 6px;
  height: 32px;
  cursor: pointer;
  transition: all 0.3s;

  @include themify($themes) {
    background: themed('colorFieldsBorder');
    border: 1px solid themed('colorFieldsBorder');
  }

  svg {
    fill: $color-additional;
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }

  &.active {
    background: $color-accent;
    border: 1px solid $color-accent;

    svg {
      fill: #000000;
    }
  }
}

.form__form-group-file {

  label {
    border-radius: 2px;
    line-height: 18px;
    font-size: 12px;
    padding: 4px 20px;
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;

    @include themify($themes) {
      border: 1px solid themed('colorFieldsBorder');
      color: themed('colorText');
    }

    &:hover {

      @include themify($themes) {
        background: themed('colorFieldsBorder');
      }
    }
  }

  span {
    padding-left: 10px;
  }

  input {
    display: none;
  }
}

.form__form-group-icon {
  padding: 6px;
  height: 32px;
  color: #4D4F5C;
  @media screen and (max-width: 767px){
    width: fit-content;
  }

  @include themify($themes) {
    background: #F0F0F7;
    border: 1px solid #E8E9EC;
  }

  svg {
    fill: #4D4F5C;
    width: 18px;
    height: 18px;
    transition: all 0.3s;
    margin-top: -5px;
  }
}

.form__form-group-description {
  font-size: 13px;
  color: $color-additional;
  line-height: 13px;
  margin-top: 2px;
  font-weight: 400;
  padding-bottom: 3px;
}

.form__button-toolbar {
  margin-top: 10px;
}

.form__form-group-input-wrap {
  width: 100%;
@media screen and (max-width: 1023px){
  .kn-multi_select__wrapper___30BEc
  {
    flex-direction: column;
    .kn-column__column___3Iwzx
    {
      border: #dddddd 1px solid;
      overflow-y: auto;
      overflow-x: hidden;
      .kn-item__with_border___3n9sw
      {
        height: 45px !important;
      }
    }
  }
}

  input:nth-child(n+1) {
    background: transparent;
    border: 1px solid $color-input-border;
    font-size: 14px;

    @media screen and (max-width: 1500px) {
      font-size: 12px;
    }

    &::-webkit-input-placeholder {
      font-family: 'Source Sans Pro', sans-serif;
      color: $color-input-placeholder;
    }
    &::-moz-placeholder {
      font-family: 'Source Sans Pro', sans-serif;
      color: $color-input-placeholder;
    }
    &:-moz-placeholder {
      font-family: 'Source Sans Pro', sans-serif;
      color: $color-input-placeholder;
    }
    &:-ms-input-placeholder {
      font-family: 'Source Sans Pro', sans-serif;
      color: $color-input-placeholder;
    }
  }
}

.form__form-group-error {
  font-size: 11px;
  line-height: 13px;
  color: #ad4444;
  margin-bottom: -5px;
  display: block;
  margin-top: 5px;
}

.form__form-group-input-wrap--error-above {

  .form__form-group-error {
    position: absolute;
    margin: 0;
    right: 0;
    top: 0;
    padding: 5px 10px;
    background: #ffbcbc;
    border-radius: 3px;

    &:after {
      content: '';
      position: absolute;
      right: 10px;
      bottom: -8px;
      border: 4px solid transparent;
      border-top: 4px solid #ffbcbc;
    }
  }
}

.form__form-group-date-cvc {
  display: flex;
  width: 100%;

  .form__form-group-date {
    width: 100%;
    margin-right: 20px;
  }

  .form__form-group-cvc {
    max-width: 280px;
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-date {
      margin-right: 0;
    }

    .form__form-group-cvc {
      max-width: 100%;
    }
  }
}

.form__form-group-id-category {
  width: 100%;
  display: flex;

  .form__form-group-id {
    min-width: 100px;
    width: 40%;
    margin-right: 20px;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-id {
      margin-right: 0;
      width: 100%;
    }
  }
}

.form__form-group-price-discount {
  width: 100%;
  display: flex;
  margin-bottom: 20px;

  & > div {
    margin-bottom: 0;
  }

  .form__form-group-price {
    margin-right: 20px;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-price {
      margin-right: 0;
    }
  }
}
.verification-icon{
  position: absolute;
  right: 10px;
  top: 10%;
  color: #4ce1b6;
}

.modal__body{
  input, textarea {
    border: 1px solid #E9E9F0;
    color: #646777;

    &::-webkit-input-placeholder {
      color: $color-additional;
    }
    &::-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: $color-additional;
    }

    @include themify($themes) {
      border: 1px solid themed('colorFieldsBorder');
      color: themed('colorText');
    }

    &[disabled] {

      @include themify($themes) {
        background: #f2f2f2;
      }

      &:focus, &:active {

        @include themify($themes) {
          border-color: themed('colorBorder');
        }
      }
    }

    &:focus, &:active {
      outline: none;
      border-color: $color-accent;
    }
  }

}
//Sosyal medya linklerinin sayfadan taşmasını engelliyor.
.social-media-links
{
  @media screen and (max-width: 1023px){
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.no-label {
  span:nth-child(1) {
    display: none;
  }
  // span:nth-child(2) {
  //   display: none;
  // }
  .form__form-group-field {
    margin-left: 0px !important;
    padding-left: 0px !important;
    margin-top: 10px !important;
  }
 .form__form-group {
    &> .form__form-group-field {
      margin-left: 0px;
      padding-left: 0px;
      margin-top: 10px;
    }
    &> span:nth-of-type(3) {
      margin-left: 0px !important;
    }
  }
}

.no-label.full_width .form__form-group .form__form-group-field{
  width: 100%;
}

.form-add-content-button {
  position: absolute;
  font-size: 14px;
  line-height: 15px;
  bottom: -18px;
  right: 0;
  color: $color-blue;
  cursor: pointer;
  &:hover {
    color: $color-blue-hover;
    text-decoration: none;
  }
}

.form-merged-column-body {
  display: flex;
  flex-wrap: wrap;

  .form-profile-image {
    max-width: 300px;
    width: 100%;

    @media screen and (max-width: 1500px) {
      max-width: 250px;
    }
  }
  .form-form-element {
    width: calc(100% - 300px);
    padding-left: 42px;

    @media screen and (max-width: 1500px) {
      width: calc(100% - 250px);
    }

    @media screen and (max-width: 1200px) {
      width: 100%;
      padding-left: 0;
      margin-top: 30px;
    }
  }
}

.term-field-desc-label {
  width: 100% !important;
  font-size: 13px;
  button {
    font-size: 13px;
    color: $color-accent;
    display: contents;

    span {
      color: $color-accent;
    }
  }
}

.term-field-checkbox-label {
  width: auto !important;
  display: contents;
}

.term-field-label{
  width: 100% !important;
}


.kn-item__with_border___3n9sw {
  .kn-item_label__label___2fJ5p {
    font-weight: 700;
  }
}
.kn-item__with_grouping___3IJz8 {
  .kn-item_label__label___2fJ5p {
    font-weight: 600;
  }
}
.__react_component_tooltip .multi-line {
  color:#fff;
  margin-bottom:2px
}

.form__form-group-field.profile_requirement {
  .form__form-group {
    margin-bottom: 0px;
  }
}

.export_headers_list_form_group{
	margin-left:auto!important;
	padding-left: 0px!important;
	width: 150%!important;
	margin-bottom: 10px;
}

.label-up{
	width: 100%!important;
	display: flex!important;
	margin: auto!important;
	margin-bottom: 50px!important;
	padding: 0px 5px 0px 5px;
	@media screen and (max-width:767px){
		margin-bottom: 100px!important;
      }
      @media screen and (min-width:768px) and (max-width:1024px){
		margin-bottom: 80px!important;
      }
}
.student_consent_text {
  @media screen and (max-width: 767px) {
    margin-left: 9%!important;
  }

  @media screen and (min-width: 768px) {
    margin-left: 14%!important;
  }
}


#student_consent_modal_radio{
  width: auto!important;
  @media screen and (max-width: 767px) {
    margin-right: 1.5rem!important;
  }

  @media screen and (min-width: 768px) {
    margin-right: 3.3rem!important;

  }}
  .st_dashboard_consent_modal{
    margin-left: 37%!important;
    margin-top:9px;

    @media screen and (max-width: 767px) {
      margin-left: 30%!important;
    }

    @media screen and (min-width: 768px) {
      margin-left: 39%!important;

    }
  }
  .student_dashboard_gsm_validation{
    width: 40%!important;
  margin-left: 30%!important;
  }
  .student_dashboard_gsm_modal{
    flex: 1!important;

    max-width: 120px;
    margin-left: 10px;
  }
  .generic_phone_section{
    flex: 1!important;
    max-width: 150px!important;

  }