.tabs {
  overflow: auto;

  .tabs__wrap {
    min-width: min-content;
    //-webkit-min-width: min-content;
    @media screen and (min-width: 414px) and (max-width: 736px) {
      // width: 0% !important;
    }
    @media screen and (max-width: 767px) {
      .tab-pane.active {
        .col-md-12.col-lg-12 {
        padding-left:0px;
        width: 80vw;
          &> .card > .card-body{
            padding-left:0px;
          }
        }
      }
    }

    .tab-pane.active .profileInfoWithImage-container{
      @media screen and (max-width: 767px){
        display: flex;
        flex-direction: column-reverse;
      }
      .card > .card-body{
        display: flex;
        flex-flow: row;
        @media screen and (max-width: 767px){
          flex-flow: column-reverse;
          padding-left: 0px;
          & > div{
            margin-left: -5vw;
          }
        }
      }
    }
  }

  .nav-tabs {
    flex-wrap: nowrap;
    background-color: #F0F0F7;
    // @media screen and (max-width: 414px) and (max-width: 736px) {
    //   width: 87% ;
    // }
    // @media screen and (max-width: 375px) and (max-width: 812px) {
    //   width: 80% ;
    // }
    

      .nav-link {
        padding: 10px 20px;
        margin-right: 0;
        transition: background-color 0.3s;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 700;
        font-size: 14px;
        color: $color-content;
        @media screen and (max-width: 1500px) {
          font-size: 13px;
        }

        @include themify($themes) {
          color: themed('colorText');
        }

        &:hover, &:focus, &:active {
          outline: none;

          @include themify($themes) {
            background-color: themed('colorHover');
            border-color: themed('colorHover');
          }
        }
      }

      .nav-link.active, .nav-link.active:focus,
      .nav-link.active:hover {
        background-color: transparent;

        @include themify($themes) {
          border-color: #dddddd;
          border-bottom-color: themed('colorBackground');
          color: themed('colorText');
        }
      }

      &.disabled .nav-link {
        color: $color-additional;
      }
  }

  .tab-pane {
    padding-top: 20px;
  }

  &.tabs--bordered-top {

    .nav-tabs {
      .nav-link {
        border-top: 2px solid transparent;
        border-radius: 0;
      }

      .nav-link.active {

        &, &:focus, &:hover {
          border-radius: 0;
          border-top: 2px solid $color-accent2;
        }
      }
    }
  }

  &.tabs--bordered-bottom {

    .nav-tabs {

      .nav-link {
        border-color: transparent;
        border-bottom: 1px solid transparent;
        padding-bottom: 11px;
        background-color: inherit;

      }

      .nav-link.active {

        &, &:focus, &:hover {
          border-radius: 0;
          border-color: transparent;
          border-bottom: 3px solid #005389;
          padding-bottom: 10px;
          padding-right: 10px;
          opacity: 1px;
        background-color: #fefefe6e;

          @media screen and (max-width: 1500px) {
            border-bottom: 2px solid #005389;
          }
        }
      }.nav-link.activePurple {

        &, &:focus, &:hover {
          border-radius: 0;
          border-color: transparent;
          background-color: white !important;
          color: purple !important;
          border-bottom: 3px solid purple;
          padding-bottom: 10px;
          padding-right: 10px;
          opacity: 1px;
          @media screen and (max-width: 1500px) {
            border-bottom: 2px solid #005389;
          }
        }
      }
    }
  }
  &.tabs--student_activities {
    
    .nav-tabs {
      background-color: rgba(0, 83, 138, 0.05);
      justify-content: center;
      place-content: flex-start;
      border-bottom: 1px solid #00538966;
     
      @media screen and (max-width: 768px) {
        place-content: space-around;
      }
      
     
  
      .nav-link {
        border-color: transparent;
        border-bottom: 1px solid transparent !important;
        padding-bottom: 11px;
        background-color: transparent;
        border: 1px solid transparent;
      }

      .nav-link.active {

        &, &:focus, &:hover {
          border-radius: 4px;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          border-color: transparent;
          border: 1px solid #00538966;
          background-color:white;
          border-bottom: 1px solid white !important;
          opacity: 1px;
          @media screen and (max-width: 1500px) {
            border-bottom: 2px solid #005389;
          }
        }
      }
    }
  }

  &.tabs--justify {
    @media screen and (min-width: 414px) and (max-width: 736px) {
      width: 120% ;
    }
    @media screen and (min-width: 375px) and (max-width: 812px) {
      width: 125% ;
    }
    .nav-tabs {
      display: flex;
      flex-wrap: nowrap;

      li {
        width: 100%;

        a {
          text-align: center;
        }
      }
    }
  }

  &.tabs--vertical {
    overflow-y: visible;

    .tabs__wrap {
      display: flex;

    }

    .nav-tabs {
      border-bottom: none;
      width: 90px;
      height: 100%;
      flex-wrap: wrap;

      li {
        margin-top: 0;
        width: 100%;

        .nav-link {
          padding: 10px 10px 10px 0;
          margin-right: 0;
          transition: background-color 0.3s;
          border-radius: 0;
          border: none;

          @include themify($themes) {
            color: themed('colorText');
            border-right: 2px solid #dddddd;
          }

          &:hover, &:focus, &:active {
            outline: none;

            @include themify($themes) {
              background-color: themed('colorHover');
            }
          }
        }

        .nav-link.active, .nav-link.active:focus,
        .nav-link.active:hover {
          background-color: transparent;

          @include themify($themes) {
            border-right-color: $color-accent2;
          }
        }

        &.disabled .nav-link {
          color: $color-additional;
        }
      }
    }

    .tab-pane {
      padding-top: 0;
      padding-left: 20px;
    }

    &.tabs--vertical-colored {

      .nav-tabs {
        li {

          .nav-link {
            border-right-color: #eeeeee;
            padding-left: 10px;
            text-align: center;
          }

          .nav-link.active, .nav-link.active:focus,
          .nav-link.active:hover {
            background-color: #eeeeee;
            border-right-color: #eeeeee;
            color: #646777;
          }
        }
      }
    }
  }
}

.survey-type-tabs{
  .nav-tabs {
    padding-bottom: 15px;
    justify-content: center;
  }
  .nav-link.active, .nav-link.active:focus {
    border-bottom-color: #dddddd !important;
    background: #70bbfd !important;
    color: #fff !important;
  }
  .nav-item {
    display: flex;
    width: 100%;
    text-align: center;
  }
  .nav-link {
    width: 100% !important;
    padding: 5px 10px !important;
    border-color: #dddddd;
    border-radius: 4px;
    margin-right: 2px !important;
    &:hover, &:focus, &:active {
      border-bottom-color: #dddddd !important;
      border-color: #dddddd !important;
    }
  }
}

.survey-question-tabs {
  .nav-tabs {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    justify-content: space-around;
  }
  .nav-item {
    display: flex;
    margin-bottom: 3px !important;
    width: 100% !important;
  }

  .nav-link.active, .nav-link.active:focus {
    border-bottom-color: #dddddd !important;
    background:  #e7e2e2 !important;
    color: #444444 !important;
  }
  .nav-link {
    width: 100% !important;
    padding: 5px 10px !important;
    border-color: #dddddd;
    border-radius: 4px;
    margin-right: 2px !important;
    &:hover, &:focus, &:active {
      border-bottom-color: #dddddd !important;
      border-color: #dddddd !important;
    }
  }
}
// student/new_cv
.profileInfoWithImage-container.row{
  width: 100%;
}
.scrollbar-none::-webkit-scrollbar {
  display: none;
}