.image-display-items__wrap {
  overflow: hidden;
  width: 100%;
  margin-bottom: 25px;
}

.image-display-items {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.image-display-single-item{
  width: 100%;
  position: relative;
}

.image-display-single-item-right-button{
  position: absolute !important;
  top: 5px;
  right: 5px;
}

.image-display-item__img-wrap {
  width: 100%;
  height: auto;
  padding: 20px;
  border: 1px solid #f2f4f7;
  display: flex;
  justify-content: center;
  overflow: hidden;

  img {
    align-self: flex-start;
  }
}
.banner-display-item__img-wrap {
  /* max-width:1987px;
  max-height:714px;
  border: 0px solid #f2f4f7;
  display: flex;
  justify-content: center;
  overflow: hidden; */

  max-width: 100%;
  display: flex;
  justify-content: center;
  object-fit: contain;
  max-height: 500px;
}

.image-display-item__img {
  width: 100%;
}
