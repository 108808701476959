
.profile__card {
  height: 100%;
  padding: 0;

  @include themify($themes) {
    background-color: themed('colorBackground');
  }

  .nav-item {
    text-transform: uppercase;
    font-weight: 500;
  }

  .tab-content {
    padding: 0 30px 40px 30px;
  }

  .timeline__content {

    @include themify($themes) {
      background: themed('colorBackgroundBody');
    }

    &:after {

      @include themify($themes) {
        border-left-color: themed('colorBackgroundBody');
      }
    }
  }

  .timeline .timeline__item:nth-child(even) {

    .timeline__content {

      &:after {

        @include themify($themes) {
          border-right-color: themed('colorBackgroundBody');
        }
      }
    }
  }

  &--calendar {
    .rbc-toolbar {
      -webkit-flex-wrap: nowrap!important;
      flex-wrap: nowrap!important;
    }
  }
}

.profile__information {
  padding: 10px 20px;
  display: flex;

  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
  }

  @media screen and (max-width: 320px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .profile__data {
    @media screen and (max-width: 320px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 0;
    }
  }
}

.profile__avatar {
  height: 110px;
  width: 110px;
  overflow: hidden;
  border-radius: 50%;

  img {
    height: 100%;
  }
}

.profile__stats {
  display: flex;
  justify-content: space-around;
}

.profile__stat {
  text-align: center;
  padding-top: 5px;
  padding-bottom: 15px;
}

.profile__stat-number {
  color: $color-blue;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}

.profile__stat-title {
  margin: 0;
  color: $color-additional;
  font-size: 12px;
  line-height: 14px;
}

.profile__data {
  padding-left: 25px;
  width: calc(100% - 70px);
}

.profile__name {
  font-weight: 900;
  text-transform: uppercase;
  margin: 0;
  line-height: 18px;
}

.profile__work {
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 0;
  opacity: 0.6;
  line-height: 18px;
}

.profile__contact {
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 18px;
}

.profile__btn {
  margin-top: 10px;
  margin-bottom: 0;
  padding: 8px 15px;
}

.cfprofile__activity_body {
  @media screen and (max-width: 500px) {
    padding-right: '200px';
    width: '100%';
  }
}
.cfprofile__activity {
  display: flex;
  border-bottom: solid 1px #e7e7e7;
  padding: 20px 0;
  @media screen and (max-width: 500px) {
    display: flex;
  }
}
.cfprofile__activity-avatar {
  background-color: #2a4173;
  max-height: 75px;
  max-width: 75px;
  font-size: 40px;
  color: #FFF;
  float: left;
}
.cfevent_day {
  text-align: center;
  color: #FFF;
}

.cfevent_month {
  font-size: 15px;
  text-align: center;
  margin-top: -15px;
}
.profile__activity {
  display: flex;
  border-bottom: solid 1px #e7e7e7;
  padding: 20px 0;
  @media screen and (max-width: 576px) {
    flex-direction: column;
  }

  &:last-child {
    border: none;
  }

  img {
    display: inline-block;
    margin-right: 20px;
    vertical-align: top;
    margin-top: 10px;
    width: auto;

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }
}
.announcement_banner_profile__activity {
  display: flex;
  border-bottom: solid 1px #e7e7e7;
  padding: 20px 0;
  @media screen and (max-width: 576px) {
    flex-direction: column;
  }

  &:last-child {
    border: none;
  }

  img {
    display: inline-block;
    margin-right: 20px;
    vertical-align: top;
    margin-top: 10px;
    width: 100%;

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }
}
.profile__activity-avatar {
  width: 64px;
  height: 64px;
  overflow: hidden;
  min-width: 64px;
  margin-right: 20px;
  font: italic small-caps bold 14px/2 cursive;
  font-style: oblique;

  img {
    height: 64px;
    width: 64px;
    margin: 0;
  }
}

.bannerprofile__activity-avatar {
  position: absolute;
  right: 30px;
  font-style: oblique;  
  img {
    margin: -10px;
    height: 100%;
    width: 35%;
    margin-left: 662px;
  }
  @media screen and (max-width: 500px) {
    img {
      display: none;
    }
  }

}

.profile__activity-name {
  font-weight: 700;
  color: $color-blue;
}

.profile__activity-date {
  font-weight: 400;
  color: $color-additional;
  font-size: 10px;
}

.profile__current-tasks-title {
  padding: 20px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  margin: 0;

  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
    color: themed('colorText');
  }

  span {
    color: $color-additional;
    font-size: 13px;
  }
}

.profile__current-tasks {
  position: relative;
  padding-bottom: 40px;
}

.profile__current-task {
  display: flex;
  margin: 0 20px;
  padding: 10px 0 10px 28px;
  position: relative;

  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
  }

  &:last-of-type {
    border-bottom: none;
  }

  .badge {
    text-transform: uppercase;
    font-size: 9px;
    padding: 6px 10px;
    display: inline-table;
    height: 20px;
    color: #ffffff;
    margin-left: 5px;

    &.badge-info {
      background-color: $color-accent;
    }

    &.badge-error {
      background-color: $color-red;
    }
  }
}

.profile__current-task-checkbox {
  position: absolute;
  left: 0;
}

.profile__see-all-tasks {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.student__profile_banner {
  text-align: left;
   display: inline-block; 
   margin: 0; 
   padding: 32px; 
   width: 100%; 
   background-position: right bottom; 
   @media screen and (max-width: 500px) {
    //  background-image: none !important;
   background-position: left top; 
   }
}